import { useEffect, useState } from "react";
import axios from "../api/axios/axios";

export default function useFetchBridgeServiceProvider(body) {
  const [activeServiceProvider, setActiveServiceProvider] = useState("");
  const [loadingActiveServiceProvider, setLoadingActiveServiceProvider] =
    useState(false);
  const { serviceTypeID, toCountryID, paymentService } = body;

  useEffect(() => {
    if (serviceTypeID && toCountryID && paymentService) {
      setLoadingActiveServiceProvider(true);
      axios
        .post(`/api/Transaction/GetBridgeServiceProvider/`,  { serviceTypeID, toCountryID, paymentService } )
        .then((res) => {
          setLoadingActiveServiceProvider(false);
          setActiveServiceProvider(res.data?.serviceProvider);
        })
        .catch((err) => {
          setLoadingActiveServiceProvider(false);
          setActiveServiceProvider("");
        });
    }
  }, [serviceTypeID, toCountryID, paymentService]);

  return { activeServiceProvider ,loadingActiveServiceProvider};
}
