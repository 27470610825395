import { useEffect, useState } from "react";
import axios from "../api/axios/axios";

export default function useFetchOperationalCountries(countryId) {
  const [countries, setCountries] = useState([]);

  useEffect(() => {
    let isMounted = true;
    if (countryId) {
      axios
        .get(`/api/Utility/GetOperationalCountries/${countryId}`)
        .then((res) => {
          if (isMounted) return setCountries(res.data);
        })
        .catch((error) => {
          if (isMounted) return setCountries([]);
        });
    }

    return () => (isMounted = false);
  }, [countryId]);

  return { countries };
}
