import { toast } from "react-toastify";
import axios from "./axios/axios";

export const deleteBeneficiary = ({ setIsLoading, beneficiaryId, setModal, setBeneficiaryDeleted }) => {
  setIsLoading(true);
  axios
    .post(`/api/Beneficiary/DeleteBeneficiary/${beneficiaryId}`)
    .then((res) => {
      setModal(false);
      setIsLoading(false);
      setBeneficiaryDeleted(true);
      toast.success("Beneficiary Deleted Successfully.");
    })
    .catch((err) => {
      setModal(false);
      toast.error("Error deleting beneficiary.");
      setIsLoading(false);
    });
};
