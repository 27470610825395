import React, { useEffect, useState } from "react";
import { useContext } from "react";

import DeliveryMethod from "./DeliveryMethod";
import { FormContext } from "../../Beneficiary";
import { deliveryMethodSchema } from "./formValidation";

const DeliveryMethodContainer = () => {
  const {
    countrySupportsRelation,
    activeStepIndex,
    setActiveStepIndex,
    formData,
    countriesData,
    singleCountryData,
    location,
    apiData: { beneficiaryRelationships },
  } = useContext(FormContext);
  // const [beneficiaryTypes] = useState([
  //   { type: "Company" },
  //   { type: "Individual" },
  // ]);

  const [benTypes, setBenTypes] = useState([]);

  useEffect(() => {
    //  TODO: refactor this
    // bank only can support C2B (ID=1) if not bank show individual only
    if (
      (location?.state?.serviceId !== formData.deliveryMethod?.deliveryOptionId &&
        Number(formData.deliveryMethod?.deliveryOptionId) !== 1) ||
      (location?.state?.serviceId === formData.deliveryMethod?.deliveryOptionId &&
        Number(location?.state?.serviceId) !== 1)
    ) {
      formData.setDeliveryMethod((prevState) => ({
        ...prevState,
        beneficiaryType: location.state?.beneficiaryType ?? "",
      }));
      let filteredBenTypes = singleCountryData?.beneficiaryTypes?.filter(({ type }) => type === "Individual");
      setBenTypes(filteredBenTypes);
    } else {
      setBenTypes(singleCountryData?.beneficiaryTypes);
    }

    return () => {};
  }, [formData.deliveryMethod.deliveryOptionId, location.state?.serviceId, singleCountryData?.beneficiaryTypes]);

  const clearStateOnCountryChange = (setFieldValue) => {
    //NB: not working
    setFieldValue("deliveryOption", "");
    setFieldValue("deliveryOptionId", "");
    setFieldValue("beneficiaryType", "");
    setFieldValue("swiftCode", "");
    setFieldValue("bankName", "");
    setFieldValue("bankCode", "");
    setFieldValue("bankBranch", "");
    setFieldValue("bankBranchCode", "");
    setFieldValue("state", "");
    setFieldValue("city", "");
  };

  const handleCountry = (val, setFieldValue) => {
    clearStateOnCountryChange(setFieldValue);
    const { id, name, callingCode, code } = val;
    let phoneCode = "+" + callingCode;

    setFieldValue("countryName", name);
    setFieldValue("countryID", id);
    setFieldValue("countryPhoneCode", phoneCode);
    setFieldValue("countryAlpha2Code", code);

    formData.setPersonalInfo({
      ...formData.personalInfo,
      phoneNumberCode: phoneCode,
    });
    formData.setDeliveryType({
      ...formData.deliveryType,
      cashDetails: {
        ...formData.deliveryType.cashDetails,
        mobileNumberCode: phoneCode,
      },
      mobileDetails: {
        ...formData.deliveryType.mobileDetails,
        walletMobileCode: phoneCode,
      },
      bankDetails: {
        ...formData.deliveryType.bankDetails,
        bankMobileCode: phoneCode,
      },
    });

    formData.setDeliveryMethod({
      ...formData.deliveryMethod,
      countryID: id,
      countryName: name,
      countryPhoneCode: callingCode,
    });
  };

  const handleDeliveryOption = (val, setFieldValue) => {
    formData.setDeliveryMethod((prevState) => ({
      ...prevState,
      deliveryOptionId: val.id,
      deliveryOption: val.serviceName,
    }));

    setFieldValue("deliveryOption", val.serviceName);
    setFieldValue("deliveryOptionId", val.id);
  };

  const handleBeneficiaryType = (val, setFieldValue) => {
    formData.setDeliveryMethod({
      ...formData.deliveryMethod,
      beneficiaryType: val.type,
    });
    setFieldValue("beneficiaryType", val.type);
  };

  const handleRelationship = (val, setFieldValue) => {
    formData.setDeliveryMethod({
      ...formData.deliveryMethod,
      relationShip: val.relation,
      relationshipID: val.id,
    });
    setFieldValue("relationshipID", val.id);
    setFieldValue("relationShip", val.relation);
  };

  const onSubmit = (values) => {
    formData.setDeliveryMethod({ ...formData.deliveryMethod, ...values });
    setActiveStepIndex(activeStepIndex + 1);
  };

  const propsData = {
    countriesData,
    singleCountryData,
    // beneficiaryTypes,
    beneficiaryRelationships,
    formData,
    handleCountry,
    handleDeliveryOption,
    handleBeneficiaryType,
    handleRelationship,
    onSubmit,
    countrySupportsRelation,
    deliveryMethodSchema: deliveryMethodSchema(countrySupportsRelation),
    location,
    benTypes,
  };

  return <DeliveryMethod propsData={propsData} />;
};

export default DeliveryMethodContainer;
