import React from "react";
import "./Support.css";

const Support = () => {
  return (
    <div className="support-content">
      <div className="support-bg">
        <h1 className="support-title">How to send Money in simple steps</h1>
      </div>
      <div className="support__info">
        <div className="support__list">
          <ol type="1">
            <li>
              <span>
                To send money, click on the SEND MONEY button and follow instructions on how you can add and save your
                beneficiary details
              </span>
            </li>
            <li>
              <span>
                Select the country you want to send money to, the delivery option preferred and you will be required to
                add the beneficiary details which will be saved for future transactions
              </span>
            </li>
            <li>
              <span>
                Description: Input the amount you'd wish to send. The system will automatically show you the fee to be
                charged, the exchange rate and the amount to be received
              </span>
            </li>
            <li>
              <span>
                This is the summary page, it is where you review your transaction details before making payment. You can
                edit the details in case you'd want to make changes
              </span>
            </li>
            <li>
              <span>
                You are now at the payment page. You can pay via MPESA, Paybill or via Pesalink from you mobile banking
                app
              </span>
            </li>
            <li>
              <span>
                Once payment has been made, you will be able to get a confirmation and download your receipt. You can also
                check the status of your transaction from your dashboard
              </span>
            </li>
          </ol>
        </div>
        <div className="support__right-bg">  
        </div>
      </div>
    </div>
  );
};

export default Support;
