import React, { useContext } from "react";
import { FormContext } from "../Registration";
import { PersonalInfo, Email, Country, PhoneNumber, Password } from "../Forms";

function Step() {
  const { activeStepIndex, setActiveStepIndex } = useContext(FormContext);
  const previousBtn = () => {
    setActiveStepIndex(activeStepIndex - 1);
  };
  let stepContent;

  switch (activeStepIndex) {
    case 0:
      stepContent = <PersonalInfo />;
      break;
    case 1:
      stepContent = <Email />;
      break;
    case 2:
      stepContent = <Country />;
      break;
    case 3:
      stepContent = <PhoneNumber />;
      break;
    case 4:
      stepContent = <Password previousBtn={previousBtn} />;
      break;
    default:
      break;
  }

  return stepContent;
}

export default Step;
