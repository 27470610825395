import classnames from "classnames";
import React, { useMemo } from "react";

export default function Pagination(props) {
  const { onPageChange, totalCount, currentPage, pageSize } = props;

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    pageSize,
  });

  if (currentPage === 0 || paginationRange.length < 2) {
    return null;
  }

  return (
    <ul>
      {paginationRange.map((pageNumber, index) => {
        return (
          <li
            key={index}
            className={classnames("pagination-item", {
              selected: pageNumber === currentPage,
            })}
            onClick={() => onPageChange(pageNumber)}
          >
            <span>{pageNumber}</span>
          </li>
        );
      })}
    </ul>
  );
}

const range = (start, end) => {
  let length = end - start + 1;
  /*
        Create an array of certain length and set the elements within it from
      start value to end value.
    */
  return Array.from({ length }, (_, idx) => idx + start);
};

export const usePagination = ({ currentPage, totalCount, pageSize }) => {
  return useMemo(() => {
    const totalPageCount = Math.ceil(totalCount / pageSize);
    return range(1, totalPageCount);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalCount, pageSize, currentPage]);
};
