import moment from "moment";
import { toast } from "react-toastify";
import { BASE_URL } from "../constants/Configuration";
import axios from "./axios/axios";

export const registerUser = ({ formData, setIsLoading, navigate }) => {
  const {
    personalInfo: { firstName, lastName, dob },
    email,
    mobileNo,
    password,
    operatingCountryID,
    code,
  } = formData;

  setIsLoading(true);
  axios
    .post(`/api/user/register`, {
      firstName: firstName,
      lastName: lastName,
      dob: moment(dob).format("YYYY-MM-DD"),
      email: email,
      userName: email,
      password: password,
      phoneNumber: code + mobileNo,
      operatingCountryID: operatingCountryID,
      createdBy: "WEB",
      platformType: "WebApp",
      returnUrl: `${BASE_URL}`,
    })
    .then((res) => {
      setIsLoading(false);
      window.dataLayer.push({ event: "user-registration" });
      navigate("/login?reg=success");
    })
    .catch((err) => {
      setIsLoading(false);
      if (err.response && err.response.data) {
        toast.error(err.response.data.message);
      } else {
        toast.error("Something went wrong. Please try again!");
      }
    });
};
