import React, { useState, useEffect } from "react";
import addPaymentIcon from "../assets/images/icons/add-payment-icon.png";
import { toast } from "react-toastify";
import axios from "../api/axios/axios";
import { Link } from "react-router-dom";
import moment from "moment";
import ContentLoader from "react-content-loader";

export default function ShortSummary() {
  const [summaryList, setSummaryList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  // TODO: create custom hook
  useEffect(() => {
    axios
      .get(`/api/Utility/GetActivitySummary`)
      .then((res) => {
        const data = res.data.sort(
          (a, b) => new Date(b.createdOn) - new Date(a.createdOn)
        );
        setSummaryList(data.slice(0, 5));
        setIsLoading(false);
      })
      .catch((err) => {
        toast.error("Unable to get summary");
        setSummaryList([]);
      });

    return () => {
      setSummaryList([]); // unmount the state
    };
  }, []);

  const contentLoader = () => (
    <ContentLoader
      speed={2}
      width={228}
      height={16}
      viewBox="0 0 228 16"
      backgroundColor="#091837"
      foregroundColor="#ffffff"
    >
      <circle cx="587" cy="223" r="8" />
      <rect x="441" y="182" rx="5" ry="5" width="220" height="10" />
      <circle cx="588" cy="232" r="8" />
      <rect x="441" y="212" rx="5" ry="5" width="220" height="10" />
      <circle cx="583" cy="226" r="8" />
      <rect x="441" y="242" rx="5" ry="5" width="220" height="10" />
      <circle cx="577" cy="223" r="8" />
      <rect x="441" y="272" rx="5" ry="5" width="220" height="10" />
      <rect x="6" y="0" rx="0" ry="0" width="222" height="16" />
    </ContentLoader>
  );
  return (
    <div className="div-40 money-half-input order-last">
      <div className="">
        <div className="process-form">
          <div className="payment-details-add">
            <img src={addPaymentIcon} alt="" />
            <h2>Account Summary</h2>
            <span className="float-right btn-padding">
              <Link to="/account-Summary" className="a-link">
                View all
              </Link>
            </span>
          </div>
          <div className="send-total-wrapper acc-summary">
            <div className="">
              <ol>
                {isLoading
                  ? [1, 2, 3, 4, 5].map((_, index) => (
                      <li key={index}>
                        <h3>{contentLoader()}</h3>
                      </li>
                    ))
                  : summaryList.map((summary, index) => (
                      <li key={index}>
                        <h3>{summary.activity}</h3>
                        <p>{moment(summary.createdOn).format("DD MMM YYYY")}</p>
                      </li>
                    ))}
              </ol>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
