import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";
import axios from "../api/axios/axios";
import CustomSlider from "../tools/CustomSlider";

export default function DashBoardCarousal() {
  const [currencies, setCurrencies] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState("");

  const CustomStyle = {
    option: (provided, state) => ({
      ...provided,
      textAlign: "left",
    }),
    control: () => ({
      border: "1px solid lightgrey",
      width: "250px",
      display: "Flex",
      alignItems: "center",
      margin: "0 auto",
    }),
  };

  useEffect(() => {
    axios
      .get(`/api/Utility/GetExchangeRates/1/100`)
      .then((res) => {
        const currencyList = res.data.items.map((item) => ({
          currency:
            item.countryCode +
            "(" +
            item.fromCurrencyCodeName +
            ") - " +
            item.toCountryCode +
            "(" +
            item.toCurrencyCodeName +
            ")",
          toCountryCode: item.toCountryCode,
          total: item,
        }));
        setCurrencies(currencyList);
      })
      .catch(() => {
        toast.error("Unable to get exchange rates");
        setCurrencies([]);
      });

    return () => {
      setCurrencies([]); // unmount the state
    };
  }, []);

  const handleCurrencies = (val) => {
    if (val) {
      setSelectedCountry(val.total);
    } else {
      setSelectedCountry("");
    }
  };

  return (
    <div className="mobile-clear">
      <div
        style={{ display: "flex", alignItems: "center" }}
        className="form-control"
      >
        <Select
          styles={CustomStyle}
          name="country"
          onChange={(val) => handleCurrencies(val)}
          options={currencies}
          getOptionLabel={(option) => option.currency}
          getOptionValue={(option) => option.toCountryCode}
          placeholder="Select to filter"
          isClearable={true}
          noOptionsMessage={() => "Rates are loading..."}
        />
        <div className="text-right margin-10-20 sendMoney-mobile-hide">
          <div className="steps-inputs-column1">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Link to="/money-transfer">Send Money</Link>
             
            </div>
          </div>
        </div>
      </div>

      <div className="div-40 money-half-input order-last">
        <div
          className="owl-carousel owl-theme"
          style={{ opacity: "1", display: "block" }}
        >
          <div className="owl-wrapper-outer">
            <div
              className="owl-wrapper"
              style={{
                width: "3744px",
                left: "0px",
                display: "block",
                transform: "translate3d(0px, 0px, 0px)",
                transition: "all 1000ms ease 0s",
              }}
            >
              <div className="owl-item" style={{ width: "468px" }}>
                <div className="exchangerate-card">
                  <CustomSlider filterBy={selectedCountry} data={currencies} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
