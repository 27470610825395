import React, { useState, useEffect } from "react";
import QRCode from "qrcode.react";
import AppStore from "../../../../assets/svgs/apple.svg";
import GooglePlay from "../../../../assets/svgs/google-play.svg";
import styles from "./DownloadApp.module.css";

const DownLoadApp = () => {
  const [value, setValue] = useState("https://play.google.com/store/search?q=upesi+money+transfer&c=apps");
  const location = window.location;
  useEffect(() => {
    const userAgent = navigator.userAgent || navigator.vendor;
    if (/android/i.test(userAgent)) {
      setValue("https://play.google.com/store/search?q=upesi+money+transfer&c=apps");
    } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      setValue("https://apps.apple.com/ke/app/upesi/id6448977046");
    }
  }, []);
  return (
    <div className="row mt-5">
      <div className="col-2 m-0 p-0">
        <QRCode
          value={window.location.href + "app?download"}
          size={300}
          style={{ height: "auto", maxWidth: "100%", width: "100%" }}
          viewBox={`0 0 256 256`}
        />
      </div>
      <div className="col-6 ms-3 d-flex flex-column mt-auto mb-4">
        <div className="text-left">
          <h3>Scan to Download</h3>
        </div>
        <div className="row align-items-center mt-3">
          <div className="col-1">
            <a
              target="_blank"
              href="https://play.google.com/store/search?q=upesi+money+transfer&c=apps"
              rel="noreferrer"
            >
              <img src={GooglePlay} className="" alt="google play icon"></img>
            </a>
          </div>
          <div className={`col text-left m-1`}>
            <a
              target="_blank"
              className={`${styles.google_apple_store}`}
              href="https://play.google.com/store/search?q=upesi+money+transfer&c=apps"
              rel="noreferrer"
            >
              Google Play
            </a>
          </div>

          <div className="col-1">
            <a target="_blank" href="https://apps.apple.com/ke/app/upesi/id6448977046" rel="noreferrer">
              <img src={AppStore} className="" alt="app store icon"></img>
            </a>
          </div>
          <div className={`col text-left m-1`}>
            <a
              className={`${styles.google_apple_store}`}
              target="_blank"
              href="https://apps.apple.com/ke/app/upesi/id6448977046"
              rel="noreferrer"
            >
              App Store
            </a>
          </div>
        </div>
        {location.search.includes("download") ? (window.location.href = value) : ""}
      </div>
    </div>
  );
};

export default DownLoadApp;
