import { useEffect, useState } from "react";
import axios from "../api/axios/axios";

export default function useFetchReasonOfRemittance({
  customerType,
  senderService,
  toCountryID,
  receiverService,
  serviceTypeID,
}) {
  const [purposeList, setPurposeList] = useState([]);

  useEffect(() => {
    if (
      toCountryID &&
      serviceTypeID &&
      receiverService &&
      senderService &&
      customerType
    ) {
      const purposeOfRemittance = {
        customerType,
        toCountryID,
        paymentService: `${senderService}2${receiverService}`,
        serviceTypeID,
        all: true,
        SystemCategoryContext: "Purpose of Remittance",
      };
      axios
        .post(`/api/Transaction/GetReasonOfRemittance`, purposeOfRemittance)
        .then((res) => {
          setPurposeList(res.data.items);
        })
        .catch(() => {
          setPurposeList([]);
        });
    }
  }, [
    customerType,
    senderService,
    toCountryID,
    receiverService,
    serviceTypeID,
  ]);

  return { purposeList, setPurposeList };
}
