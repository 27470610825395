import React from "react";
import { useNavigate } from "react-router-dom";

const KycFail = () => {
  const navigate = useNavigate();

  // OnSubmit
  const processMetaKYC = () => {
    navigate("/kyc")
  };


  return (
    <div>
      <p className="kyc-message">KYC was not successful. Please re-initiate process</p>
      <button
        style={{
          margin: "150px 0px 150px",
          backgroundColor: "rgb(231, 112, 22)",
          border: "none",
          color: "rgb(255, 255, 255)",
          padding: "13px 10px",
          borderRadius: "6px",
          width: "40%",
          transition: "all 0.9s ease 0s",
          textTransform: "uppercase",
          fontSize: "13px",
        }}
        type="submit"
        onClick={processMetaKYC}
      >
        Restart KYC
      </button>
    </div>
  );
};

export default KycFail;
