import React from "react";
import { Link } from "react-router-dom";

export default function ConfirmationPage(props) {
  return (


<div className='container-fluid align-items-center'>
<div className='container flex-column align-items-center mb-5 w-sm-95 w-md-75'>
        
        <div>
            <p className="reset-password">
            {props.message ? props.message : "Action completed successfully."}
            </p>

        </div>
        <div className="mb-5">
            <p className="terms-privacy">{props.Desc ? props.Desc : ""} <span> <Link to={`${props.ctalink ? props.ctalink : "/"}`}> {props.cta ? props.cta : "Back to home"}</Link> </span> </p>
        </div>

        <div className='success-reset rounded-circle mb-5'>


            <svg className='m-4' width="227" height="227" viewBox="0 0 227 227" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g id="check 1" clip-path="url(#clip0_1521_10)">
                    <path id="Vector" d="M196.929 28.8184L81.2699 136.117L32.3566 83.9179L0 114.238L79.0219 198.568L227 61.4139L196.929 28.8184Z" fill="white" />
                </g>
                <defs>
                    <clipPath id="clip0_1521_10">
                        <rect width="227" height="227" fill="white" />
                    </clipPath>
                </defs>
            </svg>

        </div>

        <div className="mb-1">
              <p className="terms-privacy">
                 By registering, you accept our  <span>Terms of use</span> and <span>Privacy Policy</span>
              </p>
            </div>
    
    </div>
    </div>

  );
}
