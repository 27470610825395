export function checkPasswordStrength(password) {
    var score = 0;
    if (!password) {
        return score;
    }
    if (password.length < 6) {
        return score;
    }
    if (password.length >= 8) {
        score += 10;
    }
    if (password.length >= 12) {
        score += 10;
    }
    if (password.match(/[a-z]/)) {
        score += 10;
    }
    if (password.match(/[A-Z]/)) {
        score += 10;
    }
    if (password.match(/\d+/)) {
        score += 10;
    }
    if (password.match(/[^a-zA-Z\d]/)) {
        score += 10;
    }
    return score;
};

