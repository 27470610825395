import React from "react";

const styles = {
    margin: "15px 0px 0",
    backgroundColor: "rgb(231, 112, 22)",
    border: "none",
    color: "rgb(255, 255, 255)",
    padding: "13px 10px",
    borderRadius: "6px",
    width: "50%",
    transition: "all 0.9s ease 0s",
    textTransform: "uppercase",
    fontSize: "13px",
  };

const CustomButton = (props) => {
  return (
    <>
      <button
        style={styles}
        type={props.type}
        disabled={props.disabled}       
        className={props.className}
      >
        {props.title}
        <span className="spinner"></span>
      </button>
    </>
  );
};

export default CustomButton;
