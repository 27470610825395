import React, { useContext, useState } from "react";

import debounce from "lodash.debounce";
import { FormContext } from "../../../Beneficiary";
import BankDetails from "./BankDetails";

function BankDetailsContainer() {
  const {
    activeStepIndex,
    setActiveStepIndex,
    isLoading,
    formData,
    apiData: { countryDeliveryOptionFields, bridgeBank, bridgeBankBranches, bankList, banksBySwiftCode },
    formData: {
      deliveryType: { bankDetails },
    },
  } = useContext(FormContext);

  const [viewHiddenValue, setViewHiddenValue] = useState({
    firtValue: false,
    secondValue: false,
  });
  const [passwordTypeValue, setPasswordTypeValue] = useState("");

  let swiftCodeCountryIds = [57, 224, 82];
  const { countryID } = formData.deliveryMethod;

  const toggleHiddenValue = (key) => {
    if (key === "FIRST") {
      setViewHiddenValue({
        ...viewHiddenValue,
        firtValue: !viewHiddenValue.firtValue,
      });
    } else if (key === "SECOND") {
      setViewHiddenValue({
        ...viewHiddenValue,
        secondValue: !viewHiddenValue.secondValue,
      });
    }
  };

  const handleChangeInput = (val, setFieldValue) => {
    setPasswordTypeValue(val.target.value);

    setFieldValue(val.target.name, val.target.value, false);
  };

  const handleSwiftCode = (val, setFieldValue) => {
    setFieldValue("bankName", "");
    setFieldValue("bankCode", "");
    setFieldValue("bankBranch", "");
    setFieldValue("bankBranchCode", "");

    debounced(val.target.value);
    setFieldValue("swiftCode", val.target.value);
  };

  const debounced = debounce(
    (value) =>
      formData.setDeliveryType({
        ...formData.deliveryType,
        bankDetails: { ...bankDetails, swiftCode: value },
      }),
    1000
  );

  const handleBankName = (val, setFieldValue) => {
    //bank val:  for india ifsc save option bank | other option with bank_name save bank_name || empty string
    //bank branch val:  for india ifsc save option branch | swift-code & other option save branch_name || empty string
    // let branchValue = val.ifsc ? val.branch : valswift_code ? val.branch_name : val.branch_name || "";

    let bankValue = val.ifsc ? val.bank : val.bank_name || "";
    let branchValue = val.ifsc ? val.branch : val.branch_name || "";
    let bankCode = val.bank_code ? val.bank_code : val.ifsc || "";
    let branchCode = val.branch_code ?? "";
    let swiftCode = val.swift_code && swiftCodeCountryIds.includes(countryID) ? val.swift_code : val.ifsc || "";

    formData.setDeliveryType({
      ...formData.deliveryType,
      bankDetails: {
        ...bankDetails,
        bankName: bankValue,
        bankCode,
        bankBranch: branchValue,
        bankBranchCode: branchCode,
      },
    });

    setFieldValue("bankName", bankValue);
    setFieldValue("bankCode", bankCode);
    //no branch autofill for canada
    setFieldValue("bankBranch", branchValue);
    if (countryID !== 136) {
      setFieldValue("bankBranchCode", branchCode);
    }
    setFieldValue("swiftCode", swiftCode);
  };

  const handleBankBranches = (val, setFieldValue) => {
    setFieldValue("bankBranch", val.branch_name);
    setFieldValue("bankBranchCode", val.branch_code);
  };

  const maskPassword = (value) => {
    return value.replace(/./g, "*"); // Replace each character with an asterisk
  };

  const unmaskPassword = (maskedValue) => {
    return maskedValue.replace(/\*/g, (match) => passwordTypeValue.charAt(match.index)); // Replace asterisks with actual characters
  };

  const onSubmit = (values) => {
    //Canada bank branch detail
    // const bankBranchCode = countryID === 136 ? values.bankBranch : values.bankBranchCode;
    const data = { ...formData.deliveryType, bankDetails: values };
    formData.setDeliveryType(data);
    setActiveStepIndex(activeStepIndex + 1);
  };

  let props = {
    isLoading,
    viewHiddenValue,
    setViewHiddenValue,
    toggleHiddenValue,
    formData,
    maskPassword,
    unmaskPassword,
    onSubmit,
    handleSwiftCode,
    handleBankName,
    handleBankBranches,
    handleChangeInput,
    bridgeBank,
    bridgeBankBranches,
    bankList,
    banksBySwiftCode,
    countryDeliveryOptionFields,
    swiftCodeCountryIds,
  };
  return <BankDetails props={props} />;
}

export default BankDetailsContainer;
