import { toast } from "react-toastify";
import axios from "./axios/axios";
import moment from "moment";

const SERVICE_TYPES = {
  CASH_PICKUP: "Cash Pickup",
  BANK_DEPOSIT: "Bank Deposit",
  MOBILE_MONEY: "Mobile Money",
};

export const addBeneficiary = ({
  values,
  setIsLoading,
  location,
  navigate,
  beneficiaryData,
  singleCountryData,
  userId,
}) => {
  const {
    deliveryMethod: {
      countryID,
      countryName,
      countryAlpha2Code,
      deliveryOption,
      beneficiaryType,
      relationshipID,
      relationShip,
    },
    deliveryType: {
      bankDetails: { accountName },
    },

    locationDetails: { state, city, address, street, addressLine, zipCode },

    personalInfo: {
      firstName,
      lastName,
      middleName,
      phoneNumberCode,
      phoneNumber,
      nickName,
      documentTypeID,
      idNumber,
      documentExpiresOn,
      beneficiaryId,
      businessDescription,
      businessRegistrationIssuedBy,
      typeOfBusiness,
      regionalBeneficiaryName,
      email,
    },
    paymentService,
  } = values;

  const beneficiaryPrimaryInfo = {
    countryID,
    countryName,
    nickName,
    countryAlpha2Code,
    firstName: paymentService === "C2B" && countryName === "JAPAN" ? accountName : firstName,
    middleName: middleName,
    lastName: lastName,
    userId,
    beneficiaryType,
    phoneNumber: phoneNumberCode + phoneNumber,
    id: beneficiaryId,
    state: state,
    city: city,
    zipCode: zipCode,
    address: address,
    addressLine: addressLine,
    street: street,
    documentTypeID: documentTypeID,
    idNumber: idNumber,
    documentExpiresOn: documentExpiresOn ? moment(documentExpiresOn).format("YYYY-MM-DD") : "",
    relationshipID: relationshipID ? relationshipID : null,
    relationShip: relationShip ? relationShip : null,
    businessDescription,
    businessRegistrationIssuedBy,
    typeOfBusiness,
    regionalBeneficiaryName,
    serviceType: deliveryOption === SERVICE_TYPES.BANK_DEPOSIT ? 1 : 0,
    email,
  };

  let isEditBeneficiary = location?.state?.action === "EDIT_BENEFICIARY";

  setIsLoading(true);
  axios
    .post(`/api/Beneficiary/SaveBeneficiary`, beneficiaryPrimaryInfo)
    .then((res) => {
      // Cash pickup api
      if (deliveryOption === SERVICE_TYPES.CASH_PICKUP) {
        saveCashPickupDetails({
          userId,
          res,
          values,
          beneficiaryData,
          beneficiaryType,
          setIsLoading,
          navigate,
          location,
          singleCountryData,
          beneficiaryId,
          isEditBeneficiary,
        });
      }
      // Bank deatils api
      if (deliveryOption === SERVICE_TYPES.BANK_DEPOSIT) {
        saveBankDetails({
          userId,
          res,
          values,
          setIsLoading,
          location,
          navigate,
          beneficiaryData,
          beneficiaryType,
          singleCountryData,
          beneficiaryId,
          isEditBeneficiary,
        });
      }

      // Wallet details Api
      if (deliveryOption.toLowerCase().trim() === SERVICE_TYPES.MOBILE_MONEY.toLowerCase().trim()) {
        saveMobileWalletDetails({
          res,
          values,
          beneficiaryData,
          beneficiaryType,
          setIsLoading,
          navigate,
          location,
          singleCountryData,
          userId,
          beneficiaryId,
          isEditBeneficiary,
        });
      }
    })
    .catch((err) => {
      if (err?.response?.data?.errors?.length > 0) {
        toast.error(err.response.data.errors[0].description);
      } else {
        toast.error("Error saving beneficiary.");
      }
      setIsLoading(false);
    });
};

const saveBankDetails = ({
  userId,
  res,
  values,
  setIsLoading,
  location,
  navigate,
  beneficiaryData,
  singleCountryData,
  beneficiaryId,
  isEditBeneficiary,
}) => {
  const {
    personalInfo: { phoneNumber },
    deliveryMethod: { beneficiaryType },
    deliveryType: {
      bankDetails: {
        swiftCode,
        bankName,
        bankCode,
        bankBranch,
        bankBranchCode,
        sortCode,
        accountName,
        accountNumber,
        bankMobileNumber,
        bankMobileCode,
        bankId,
      },
    },
  } = values;

  axios
    .post(`/api/Beneficiary/SaveBeneficiaryBankDetails`, {
      beneficiaryID: isEditBeneficiary ? beneficiaryId : res.data?.id,
      bankName,
      swiftCode,
      bankCode,
      bankBranch,
      bankBranchCode,
      accountName,
      sortCode,
      accountNumber,
      phoneNumber: `${bankMobileCode}${bankMobileNumber ? bankMobileNumber : phoneNumber}`,
      userId,
      id: bankId,
    })
    .then(() => {
      setIsLoading(false);
      toast.success("Beneficiary details saved successfully");
      if (!location.state || isEditBeneficiary) {
        navigate("/manage-beneficiaries");
      } else {
        navigate("/money-transfer", {
          state: {
            ...beneficiaryData,
            beneficiaryType,
            id: res.data?.id,
            singleCountryData,
          },
        });
      }
    })
    .catch((err) => {
      toast.error("Error saving beneficiary bank details.");
      setIsLoading(false);
    });
};

const saveCashPickupDetails = ({
  userId,
  res,
  values,
  beneficiaryData,
  beneficiaryType,
  setIsLoading,
  navigate,
  location,
  singleCountryData,
  isEditBeneficiary,
  beneficiaryId,
}) => {
  const {
    deliveryType: {
      cashDetails: { cashPickupLocation, locationId },
    },
    locationDetails: { state, city, address },
  } = values;

  axios
    .post(`/api/Beneficiary/SaveBeneficiaryLocationDetails`, {
      beneficiaryID: isEditBeneficiary ? beneficiaryId : res.data?.id,
      state: state,
      city: city,
      address: address,
      userId,
      pickupLocation: cashPickupLocation,
      id: locationId,
    })
    .then(() => {
      setIsLoading(false);
      toast.success("Beneficiary details saved successfully");
      if (!location.state || isEditBeneficiary) {
        navigate("/manage-beneficiaries");
      } else {
        navigate("/money-transfer", {
          state: {
            ...beneficiaryData,
            id: res.data?.id,
            beneficiaryType,
            singleCountryData,
          },
        });
      }
    })
    .catch((err) => {
      toast.error("Error saving beneficiary cash pickup details.");
      setIsLoading(false);
    });
};

const saveMobileWalletDetails = ({
  res,
  values,
  beneficiaryData,
  beneficiaryType,
  setIsLoading,
  navigate,
  location,
  singleCountryData,
  isEditBeneficiary,
  beneficiaryId,
}) => {
  const {
    deliveryType: {
      mobileDetails: { walletMobileCode, walletMobileNumber, walletId, id, hasMobileMoneyProvider },
    },
    personalInfo: { nickName },
  } = values;
  axios
    .post(`/api/Beneficiary/SaveBeneficiaryMobileWalletDetails`, {
      beneficiaryID: isEditBeneficiary ? beneficiaryId : res.data?.id,
      mobileMoneyProviderID: hasMobileMoneyProvider ? 27 : 0, // 27 is FlexPay operator(just for UG)
      mobileMoneyProviderName: nickName,
      mobileNumber: walletMobileCode + walletMobileNumber,
      id,
      eWalletId: walletId,
    })
    .then(() => {
      setIsLoading(false);
      toast.success("Beneficiary details saved successfully");
      if (!location.state || isEditBeneficiary) {
        navigate("/manage-beneficiaries");
      } else {
        navigate("/money-transfer", {
          state: {
            ...beneficiaryData,
            id: res.data?.id,
            beneficiaryType,
            singleCountryData,
          },
        });
      }
    })
    .catch((err) => {
      toast.error("Error saving beneficiary wallet details.");
      setIsLoading(false);
    });
};
