export const flagSpriteImages = [
  { countryCode: "AD", X: "-7402px", Y: "-2618px" },
  { countryCode: "CN", X: "-8349px", Y: "-2426px" },
  { countryCode: "AE", X: "-7402px", Y: "-2618px" },
  { countryCode: "AF", X: "-7061px", Y: "-2426px" },
  { countryCode: "AG", X: "-7061px", Y: "-2426px" },
  { countryCode: "AG", X: "-7117px", Y: "-2426px" },
  { countryCode: "AI", X: "-8014px", Y: "-2531px" },
  { countryCode: "AL", X: "-6726px", Y: "-2426px" },
  { countryCode: "AM", X: "-7173px", Y: "-2426px" },
  { countryCode: "AO", X: "-7229px", Y: "-2426px" },
  { countryCode: "AR", X: "-6894px", Y: "-2531px" },
  { countryCode: "AT", X: "-7285px", Y: "-2426px" },
  { countryCode: "AU", X: "-7341px", Y: "-2426px" },
  { countryCode: "AW", X: "-7397px", Y: "-2426px" },
  { countryCode: "AZ", X: "-7453px", Y: "-2426px" },
  { countryCode: "BA", X: "-8518px", Y: "-2531px" },
  { countryCode: "BB", X: "-7509px", Y: "-2426px" },
  { countryCode: "BD", X: "-7565px", Y: "-2426px" },
  { countryCode: "BE", X: "-7621px", Y: "-2426px" },
  { countryCode: "BF", X: "-7677px", Y: "-2426px" },
  { countryCode: "BG", X: "-6950px", Y: "-2531px" },
  { countryCode: "BH", X: "-7006px", Y: "-2531px" },
  { countryCode: "BI", X: "-7733px", Y: "-2426px" },
  { countryCode: "BJ", X: "-7789px", Y: "-2426px" },
  { countryCode: "BM", X: "-7845px", Y: "-2426px" },
  { countryCode: "BN", X: "-7872px", Y: "-2618px" },
  { countryCode: "BO", X: "-7347px", Y: "-2618px" },
  { countryCode: "BS", X: "-7928px", Y: "-2618px" },
  { countryCode: "BT", X: "-7901px", Y: "-2426px" },
  { countryCode: "BW", X: "-7957px", Y: "-2426px" },
  { countryCode: "BY", X: "-8070px", Y: "-2531px" },
  { countryCode: "BZ", X: "-7062px", Y: "-2531px" },
  { countryCode: "CA", X: "-8013px", Y: "-2426px" },
  { countryCode: "CF", X: "-8069px", Y: "-2426px" },
  { countryCode: "CD", X: "-8125px", Y: "-2426px" },
  { countryCode: "CH", X: "-5511px", Y: "-2712px" },
  { countryCode: "CG", X: "-7562px", Y: "-2618px" },
  { countryCode: "CI", X: "-8181px", Y: "-2426px" },
  { countryCode: "CK", X: "-7984px", Y: "-2618px" },
  { countryCode: "CO", X: "-8237px", Y: "-2426px" },
  { countryCode: "CR", X: "-8293px", Y: "-2426px" },
  { countryCode: "CN", X: "-8349px", Y: "-2426px" },
  { countryCode: "CV", X: "-8405px", Y: "-2426px" },
  { countryCode: "CU", X: "-8040px", Y: "-2618px" },
  { countryCode: "CS", X: "-8096px", Y: "-2618px" },
  { countryCode: "CY", X: "-8461px", Y: "-2426px" },
  { countryCode: "CZ", X: "-8517px", Y: "-2426px" },
  { countryCode: "DE", X: "-7118px", Y: "-2531px" },
  { countryCode: "DJ", X: "-7005px", Y: "-2470px" },
  { countryCode: "DK", X: "-7614px", Y: "-2618px" },
  { countryCode: "DM", X: "-8152px", Y: "-2618px" },
  { countryCode: "DZ", X: "-7061px", Y: "-2470px" },
  { countryCode: "DO", X: "-7117px", Y: "-2470px" },
  { countryCode: "EC", X: "-7173px", Y: "-2470px" },
  { countryCode: "EE", X: "-6726px", Y: "-2531px" },
  { countryCode: "ES", X: "-7229px", Y: "-2470px" },
  { countryCode: "ER", X: "-8208px", Y: "-2618px" },
  { countryCode: "ET", X: "-8264px", Y: "-2618px" },
  { countryCode: "EU", X: "-7285px", Y: "-2470px" },
  { countryCode: "FJ", X: "-8320px", Y: "-2618px" },
  { countryCode: "FI", X: "-7174px", Y: "-2531px" },
  { countryCode: "FR", X: "-7341px", Y: "-2470px" },
  { countryCode: "GB", X: "-8376px", Y: "-2618px" },
  { countryCode: "GA", X: "-7666px", Y: "-2618px" },
  { countryCode: "GD", X: "-7230px", Y: "-2531px" },
  { countryCode: "GE", X: "-7397px", Y: "-2470px" },
  { countryCode: "GG", X: "-7453px", Y: "-2470px" },
  { countryCode: "GH", X: "-7509px", Y: "-2470px" },
  { countryCode: "GI", X: "-8432px", Y: "-2618px" },
  { countryCode: "GM", X: "-7565px", Y: "-2470px" },
  { countryCode: "GN", X: "-7621px", Y: "-2470px" },
  { countryCode: "GQ", X: "-7677px", Y: "-2470px" },
  { countryCode: "GR", X: "-7733px", Y: "-2470px" },
  { countryCode: "GS", X: "-6451px", Y: "-2426px" },
  { countryCode: "GT", X: "-7286px", Y: "-2531px" },
  { countryCode: "GW", X: "-8488px", Y: "-2618px" },
  { countryCode: "GY", X: "-7342px", Y: "-2531px" },
  { countryCode: "HK", X: "-7789px", Y: "-2470px" },
  { countryCode: "HR", X: "-6451px", Y: "-2662px" },
  { countryCode: "HN", X: "-7845px", Y: "-2470px" },
  { countryCode: "HU", X: "-7901px", Y: "-2470px" },
  { countryCode: "HT", X: "-7398px", Y: "-2531px" },
  { countryCode: "ID", X: "-7957px", Y: "-2470px" },
  { countryCode: "IE", X: "-6507px", Y: "-2662px" },
  { countryCode: "IL", X: "-7456px", Y: "-2618px" },
  { countryCode: "IM", X: "-6563px", Y: "-2662px" },
  { countryCode: "IQ", X: "-8013px", Y: "-2470px" },
  { countryCode: "IN", X: "-8069px", Y: "-2470px" },
  { countryCode: "IS", X: "-7509px", Y: "-2618px" },
  { countryCode: "IT", X: "-8125px", Y: "-2470px" },
  { countryCode: "JM", X: "-6619px", Y: "-2662px" },
  { countryCode: "JE", X: "-6865px", Y: "-2426px" },
  { countryCode: "JO", X: "-6675px", Y: "-2662px" },
  { countryCode: "IR", X: "-8126px", Y: "-2531px" },
  { countryCode: "JP", X: "-8181px", Y: "-2470px" },
  { countryCode: "KG", X: "-7454px", Y: "-2531px" },
  { countryCode: "KE", X: "-8237px", Y: "-2470px" },
  { countryCode: "KH", X: "-6782px", Y: "-2531px" },
  { countryCode: "KM", X: "-8293px", Y: "-2470px" },
  { countryCode: "EG", X: "-4969px", Y: "-10px" },
  { countryCode: "BR", X: "-10px", Y: "-3322px" },
  { countryCode: "CM", X: "-10px", Y: "-10px" },
  { countryCode: "KN", X: "-8349px", Y: "-2470px" },
  { countryCode: "KP", X: "-6731px", Y: "-2662px" },
  { countryCode: "KR", X: "-8405px", Y: "-2470px" },
  { countryCode: "KW", X: "-6787px", Y: "-2662px" },
  { countryCode: "KY", X: "-6843px", Y: "-2662px" },
  { countryCode: "KZ", X: "-8461px", Y: "-2470px" },
  { countryCode: "LA", X: "-8517px", Y: "-2470px" },
  { countryCode: "LB", X: "-6451px", Y: "-2574px" },
  { countryCode: "LC", X: "-6899px", Y: "-2662px" },
  { countryCode: "LI", X: "-7510px", Y: "-2531px" },
  { countryCode: "LK", X: "-6507px", Y: "-2574px" },
  { countryCode: "LR", X: "-8406px", Y: "-2531px" },
  { countryCode: "LS", X: "-6563px", Y: "-2574px" },
  { countryCode: "LT", X: "-7566px", Y: "-2531px" },
  { countryCode: "LU", X: "-7622px", Y: "-2531px" },
  { countryCode: "LV", X: "-6955px", Y: "-2662px" },
  { countryCode: "LY", X: "-7011px", Y: "-2662px" },
  { countryCode: "MC", X: "-7822px", Y: "-2618px" },
  { countryCode: "MD", X: "-7067px", Y: "-2662px" },
  { countryCode: "MG", X: "-4969px", Y: "-2426px" },
  { countryCode: "MH", X: "-8462px", Y: "-2531px" },
  { countryCode: "MK", X: "-7123px", Y: "-2662px" },
  { countryCode: "ML", X: "-6619px", Y: "-2574px" },
  { countryCode: "MM", X: "-6675px", Y: "-2574px" },
  { countryCode: "MA", X: "-3672px", Y: "-3322px" },
  { countryCode: "MN", X: "-7179px", Y: "-2662px" },
  { countryCode: "MO", X: "-6731px", Y: "-2574px" },
  { countryCode: "MR", X: "-6787px", Y: "-2574px" },
  { countryCode: "MS", X: "-7235px", Y: "-2662px" },
  { countryCode: "MT", X: "-6843px", Y: "-2574px" },
  { countryCode: "MU", X: "-6899px", Y: "-2574px" },
  { countryCode: "MV", X: "-6955px", Y: "-2574px" },
  { countryCode: "MW", X: "-10px", Y: "-5579px" },
  { countryCode: "MX", X: "-8182px", Y: "-2531px" },
  { countryCode: "MY", X: "-7291px", Y: "-2662px" },
  { countryCode: "MZ", X: "-7011px", Y: "-2574px" },
  { countryCode: "NA", X: "-7067px", Y: "-2574px" },
  { countryCode: "NE", X: "-5463px", Y: "-2712px" },
  { countryCode: "NG", X: "-7347px", Y: "-2662px" },
  { countryCode: "NI", X: "-7678px", Y: "-2531px" },
  { countryCode: "NL", X: "-7123px", Y: "-2574px" },
  { countryCode: "NO", X: "-7179px", Y: "-2574px" },
  { countryCode: "NP", X: "-5555px", Y: "-2712px" },
  { countryCode: "NR", X: "-7403px", Y: "-2662px" },
  { countryCode: "NU", X: "-7459px", Y: "-2662px" },
  { countryCode: "NZ", X: "-7515px", Y: "-2662px" },
  { countryCode: "OM", X: "-6838px", Y: "-2531px" },
  { countryCode: "PE", X: "-7235px", Y: "-2574px" },
  { countryCode: "PG", X: "-7718px", Y: "-2618px" },
  { countryCode: "PH", X: "-7291px", Y: "-2574px" },
  { countryCode: "PK", X: "-7347px", Y: "-2574px" },
  { countryCode: "PL", X: "-7734px", Y: "-2531px" },
  { countryCode: "PS", X: "-7571px", Y: "-2662px" },
  { countryCode: "PT", X: "-7403px", Y: "-2574px" },
  { countryCode: "PW", X: "-7790px", Y: "-2531px" },
  { countryCode: "PY", X: "-8294px", Y: "-2531px" },
  { countryCode: "QA", X: "-7963px", Y: "-2662px" },
  { countryCode: "RO", X: "-7459px", Y: "-2574px" },
  { countryCode: "RU", X: "-7515px", Y: "-2574px" },
  { countryCode: "RW", X: "-7571px", Y: "-2574px" },
  { countryCode: "SA", X: "-7627px", Y: "-2574px" },
  { countryCode: "SB", X: "-7627px", Y: "-2662px" },
  { countryCode: "SD", X: "-7683px", Y: "-2662px" },
  { countryCode: "SE", X: "-7846px", Y: "-2531px" },
  { countryCode: "SG", X: "-7683px", Y: "-2574px" },
  { countryCode: "SI", X: "-7739px", Y: "-2662px" },
  { countryCode: "SK", X: "-7739px", Y: "-2574px" },
  { countryCode: "SL", X: "-7795px", Y: "-2574px" },
  { countryCode: "SM", X: "-7770px", Y: "-2618px" },
  { countryCode: "SN", X: "-7851px", Y: "-2574px" },
  { countryCode: "SO", X: "-7907px", Y: "-2574px" },
  { countryCode: "SR", X: "-7963px", Y: "-2574px" },
  { countryCode: "SS", X: "-7795px", Y: "-2662px" },
  { countryCode: "ST", X: "-8019px", Y: "-2574px" },
  { countryCode: "SV", X: "-8350px", Y: "-2531px" },
  { countryCode: "SY", X: "-8075px", Y: "-2574px" },
  { countryCode: "SZ", X: "-8131px", Y: "-2574px" },
  { countryCode: "TC", X: "-8187px", Y: "-2574px" },
  { countryCode: "TD", X: "-8243px", Y: "-2574px" },
  { countryCode: "TG", X: "-8299px", Y: "-2574px" },
  { countryCode: "TH", X: "-8355px", Y: "-2574px" },
  { countryCode: "TJ", X: "-8411px", Y: "-2574px" },
  { countryCode: "TL", X: "-7851px", Y: "-2662px" },
  { countryCode: "TM", X: "-8467px", Y: "-2574px" },
  { countryCode: "TN", X: "-8523px", Y: "-2574px" },
  { countryCode: "TO", X: "-7907px", Y: "-2662px" },
  { countryCode: "TR", X: "-6451px", Y: "-2618px" },
  { countryCode: "TT", X: "-6507px", Y: "-2618px" },
  { countryCode: "TW", X: "-6563px", Y: "-2618px" },
  { countryCode: "TZ", X: "-6619px", Y: "-2618px" },
  { countryCode: "UA", X: "-6675px", Y: "-2618px" },
  { countryCode: "UG", X: "-6731px", Y: "-2618px" },
  { countryCode: "US", X: "-7902px", Y: "-2531px" },
  { countryCode: "UY", X: "-6787px", Y: "-2618px" },
  { countryCode: "UZ", X: "-6843px", Y: "-2618px" },
  { countryCode: "VA", X: "-6899px", Y: "-2618px" },
  { countryCode: "VC", X: "-6955px", Y: "-2618px" },
  { countryCode: "VE", X: "-7011px", Y: "-2618px" },
  { countryCode: "VG", X: "-8238px", Y: "-2531px" },
  { countryCode: "VN", X: "-7067px", Y: "-2618px" },
  { countryCode: "VU", X: "-7958px", Y: "-2531px" },
  { countryCode: "WS", X: "-7123px", Y: "-2618px" },
  { countryCode: "XK", X: "-7179px", Y: "-2618px" },
  { countryCode: "YE", X: "-7235px", Y: "-2618px" },
  { countryCode: "ZA", X: "-5463px", Y: "-2426px" },
  { countryCode: "ZM", X: "-5957px", Y: "-2426px" },
  { countryCode: "ZW", X: "-7291px", Y: "-2618px" },
];

export const dynamicStyle = (countryCode) => {
  let flagImage = {};
  flagSpriteImages.forEach((flag) => {
    if (countryCode === flag.countryCode) {
      flagImage = {
        backgroundPosition: `${flag.X} ${flag.Y}`,
      };
    }
  });
  return flagImage;
};
