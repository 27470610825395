import { toast } from "react-toastify";
import axios from "./axios/axios";

export const addTransactionSupportDoc = async ({ Id, files, setIsLoading,userId }) => {
  let bodyFormData = new FormData();
  bodyFormData.append("Id", `${Id}`);
  bodyFormData.append("files", files);
  bodyFormData.append("userId", `${userId}`);

  try {
    setIsLoading(true);

    const res = await axios.post(
      `/api/Transaction/AddTransactionSupportDocument`,
      bodyFormData
    );
    setIsLoading(false);

    return res.data;
  } catch (error) {
    setIsLoading(false);
    toast.error("Failed to complete transaction");

    return error;
  }
};
