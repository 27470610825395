import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";

import axios from "../../../api/axios/axios";
import EncryptOTP from "../../../utils/EncrypPassword";
import { generateOTP } from "../../../utils/generateOTP";

const timeOut = 120;
export default function OTPVerification({ success, config, setShowOTPView }) {
  const [isDisabled, setDisabled] = useState(true);
  const [isLoading, setLoading] = useState(true);
  const [input, setInput] = useState("");
  const [otp, setOtp] = useState(null);
  const [error, setError] = useState(null);
  const [start, startCountDown] = useState(false);
  const [countDown, setCountdown] = useState(timeOut);

  let randomOTP = generateOTP();
  let encryptedOTP = EncryptOTP(randomOTP);

  useEffect(() => {
    sendOtp(config.type, config.receiver, encryptedOTP);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [config]);

  const onChange = (e) => {
    const { value } = e.target;

    let val = value;
    val = value ? value.split("-").join("") : ""; // Remove dash (-) if mistakenly entered.
    let finalVal = val && val.match(/.{1,1}/g).join("-");

    setInput(finalVal);
  };

  const editReceiverDetail = () => {
    setShowOTPView(false);
  };

  const match = () => {
    if (otp === null) {
      setError("Invalid OTP");
    } else if (input.split("-").join("").trim() === "" + otp) {
      success();
      setOtp(null);
      setInput("");
    } else {
      setError("Invalid OTP");
    }
  };

  const otpExpired = () => {
    setOtp(null);
    setDisabled(false);
    startCountDown(false);
    setCountdown(timeOut);
  };

  const resend = () => {
    setInput("");
    setDisabled(true);
    setLoading(true);
    setError(null);

    sendOtp(config.type, config.receiver, encryptedOTP);
  };

  const Timer = ({ time, timerComplete }) => {
    useEffect(() => {
      let mounted = true;
      setTimeout(() => {
        if (mounted) {
          countDown - 1 < 0 ? timerComplete() : setCountdown(countDown - 1);
        }
      }, 1000);
      return () => (mounted = false);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [countDown, timerComplete]);

    return <>Resend {countDown > 0 ? <>in: {countDown} </> : null}</>;
  };

  const sendOtp = (type, receiver, random) => {
    if (type === "sms") {
      axios
        .get(`/api/user/sendverificationsms?receiver=${receiver}&message=${random}`)
        .then((response) => {
          if (response.data?.success) {
            setOtp(randomOTP);
            setLoading(false);
            startCountDown(true);
          } else {
            setLoading(false);
            setDisabled(false);
            setError(response.data?.remarks);
            // setError("An issue occurred. Click 'Resend' to retry");
          }
          // if (response.data) {
          //   setOtp(randomOTP);
          //   setLoading(false);
          //   startCountDown(true);
          // } else {
          //   setLoading(false);
          //   setDisabled(false);
          //   setError("An issue occurred. Click 'Resend' to retry");
          // }
        })
        .catch((err) => {
          setError("Error sending OTP. Please try again");
          if (err.response.data) toast.error(err.response.data);
        });
    } else if (type === "email") {
      axios
        .get(`/api/user/sendverificationemail?receiver=${receiver}&message=${random}`)
        .then((response) => {
          if (response.data?.success) {
            setOtp(randomOTP);
            setLoading(false);
            startCountDown(true);
          } else {
            setLoading(false);
            setDisabled(false);
            setError(response.data?.remarks);
            // setError("An issue occurred. Click 'Resend' to retry");
          }
        })
        .catch((err) => {
          setError("Erorr sending OTP. Please try again");
          if (err.response.data) toast.error(err.response.data);
        });
    }
  };

  return (
    <div className="row justify-content-center">
      <div className="col-12 mt-4 h3 text-center step-title">Enter the 6-digit code</div>
      <p className="col-12 h6 mt-0">
        We sent it to{" "}
        <strong>
          {config.type === "sms" ? `` : ""}
          {config.receiver}
        </strong>
        .
        <button className="btn btn-link" onClick={editReceiverDetail}>
          Change
        </button>
      </p>
      {isLoading ? (
        <div className="col-12">
          <p>Sending...</p>
          <div className="loader"></div>
        </div>
      ) : error && !otp ? (
        <div className="error-text text-center py-4">{error}</div>
      ) : (
        <div className="col-12 col-sm-6 col-lg-4 mb-4">
          <label className="text-left">Your 6-digit code</label>
          <input
            className="form-control form-input"
            type="text"
            value={input}
            onFocus={(e) => {
              setError(null);
              e.target.placeholder = "";
            }}
            onChange={onChange}
            placeholder="8-5-6-9-2-5"
            pattern="/^(\d+-?)+\d+$/"
            id="e"
            required
          />
          {error ? <div className="error-text text-center">{error}</div> : null}
        </div>
      )}

      {isLoading ? (
        <></>
      ) : (
        <>
          <div className="row col-12 justify-content-center p-0">
            <div className="col-12 col-sm-6 col-lg-4 mb-4">
              <button
                disabled={isDisabled}
                onClick={resend}
                className={`btn btn-light w-100 pointer ${isDisabled ? "mouse-not-allowed" : ""}`}
              >
                {start ? (
                  <Timer time={60} timerComplete={() => otpExpired()} />
                ) : (
                  <span style={{ color: "#f28021" }}>Resend</span>
                )}
              </button>
            </div>
            <div className="row col-12 justify-content-center p-0">
              <div className="col-12 col-sm-6 col-lg-4">
                <button className="btn btn-orange w-100" onClick={match} disabled={!otp || error}>
                  Submit
                </button>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}
