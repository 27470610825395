import { toast } from "react-toastify";
import axios from "./axios/axios";

export const downloadReceipt = ({
  obj,
  setIsLoading,
  // setId,
  // setReceiptPdf,
}) => {
  setIsLoading(true);
  // setId(obj.id);
  axios
    .get(`/api/Transaction/GetPdfReceipt/${obj.id}`)
    .then((res) => {
      // setReceiptPdf(res.data.result);
      if (res.data) {
        const linkSource = `data:application/pdf;base64,${res.data}`;
        const downloadLink = document.createElement("a");
        const fileName = "receipt.pdf";

        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
      } else {
        toast.error("Receipt not found");
      }

      setIsLoading(false);
    })
    .catch((err) => {
      toast.error("Error to get user transaction by id");
      setIsLoading(false);
    });
};
