import { useEffect, useState } from "react";
import axios from "../api/axios/axios";

export default function useFetchAvailableCurrencies({
  senderCountryId,
  setCurrencies,
  setFormData,
}) {
  const [senderCurrency, setSenderCurrency] = useState([]);

  useEffect(() => {
    (async function () {
      try {
        if (senderCountryId) {
          const res = await axios.get(
            `/api/Utility/GetAvailableCurrencies/${senderCountryId}`
          );

          const currency = res.data.map((data) => ({
            name: data.code,
            id: data.id,
          }));

          setSenderCurrency(currency);
          setCurrencies((prevCurrency) => [...currency, ...prevCurrency]);
          setFormData((prevState) => ({
            ...prevState,
            currency: currency[0]?.name,
            currencyID: currency[0]?.id,
          }));
        }
      } catch (error) {}
    })();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [senderCountryId]);

  return { senderCurrency };
}
