import React from "react";
import homeSlider from "../assets/images/home-slider4.png";
import location from "../assets/images/icons/contact-icon1.png";
import address from "../assets/images/icons/contact-icon2.png";
import contact from "../assets/images/icons/contact-icon3.png";
import { Link } from "react-router-dom";
import { getSessionData } from "../utils/session";

const Contact = () => {
  const isLoggedIn = getSessionData("token");
  return (
    <>
      <section>
        <div className="contact-banner">
          <div className="contact-banner-image">
            <img
              className={!isLoggedIn ? "contact-banner-prelogin" : ""}
              src={homeSlider}
              alt="Contact Us Banner"
            />
          </div>
          <div
            data-wow-delay="1.5s"
            className="slider-copy wow slideInLeft"
            style={{
              visibility: "visible",
              animationDelay: "1.5s",
              animationName: "slideInLeft",
            }}
          >
            <div className="display-caption">
              <div className="slider-vertical">
                <h1>
                  <span>GET IN TOUCH WITH US TODAY!</span>
                </h1>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="beneficiary-details-wrapper">
          <div className="send-total-wrapper mob-padding-remove">
            <div className="">
              <div className="contact-container">
                <div className="contact-background single-contact">
                  <div className="margin-bottom">
                    <img src={location} alt="location" />
                  </div>
                  <h3 className="margin-bottom">Physical Location</h3>
                  <p>
                    3rd Floor, Morningside Office Park,Ngong Road, Nairobi
                    Kenya.
                  </p>
                  <p></p>
                </div>
                <div className="contact-background single-contact">
                  <div className="margin-bottom">
                    <img src={address} alt="Email Address" />
                  </div>
                  <h3 className="margin-bottom">Email Address</h3>
                  <p>
                    <a href="mailto:customerservice@upesi.co.ke">
                      customerservice@upesi.co.ke
                    </a>
                  </p>
                </div>
                <div className="contact-background single-contact">
                  <div className="margin-bottom">
                    <img src={contact} alt="Contact Info" />
                  </div>
                  <h3 className="margin-bottom">Contact Info</h3>
                  <p>
                    <a
                      href="https://wa.me/254770999865?text=I'm%20not%20able%20to%20create%20an%20account"
                      target="_blank"
                      rel="noreferrer"
                    >
                      WhatsApp Us +254770999865, <br /> +254 709 656 000
                    </a>
                  </p>
                  <p></p>
                </div>
                <div className="contact-background single-contact">
                  <Link to="/contact-form">
                    <div className="margin-bottom">
                      <img
                        src={address}
                        style={{ width: "24px" }}
                        alt="Fill out form"
                      />
                    </div>
                    <h3 className="margin-bottom">Contact form</h3>
                    <p>Fill out the contact from</p>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Contact;
