import React from "react";
import Slider from "react-slick";
import {
  SampleNextArrow,
  SamplePrevArrow,
} from "../../../../components/Sendmoneyslider";
import styles from "./Partners.module.css";

import EmqGrey from "../../../../assets/images/partners/SVGs/Emq-02.svg";
import masterCardSvgGrey from "../../../../assets/images/partners/SVGs/master_card-02.svg";
import safcom from "../../../../assets/images/partners/SVGs/safcom-02.svg";
import terrapay from "../../../../assets/images/partners/SVGs/terrapay-02.svg";

const Partners = () => {
  var settings = {
    slidesToShow: 4,
    slidesToScroll: 1,
    dots: false,
    infinite: true,
    speed: 500,
    initialSlide: 0,
    autoplay: true,
    autoplaySpeed: 4000,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="row mt-4">
      <div className="row pb-5">
        <div className="col-12">
          <h1>Our Global Partners</h1>
        </div>
        <div className="col-12">
          <h3 className={styles.partners__text}>
            We make sure your money is delivered quickly and safely. Choose from
            our network of trusted banks, cash pickup locations or mobile money.
          </h3>
        </div>
        <Slider {...settings} className={styles.partner_logos}>
          <div className={`${styles.partner} ${styles.mastercard_logo}`}>
            <img
              className="w-75"
              src={masterCardSvgGrey}
              alt="mastercard logo"
            />
          </div>

          <div className={`${styles.emq_logo} ${styles.partner}`}>
            <img className="" src={EmqGrey} alt="emq logo" />
          </div>

          <div className={`${styles.partner} ${styles.terrapay_logo}`}>
            <img className="" src={terrapay} alt="terrapay logo" />
          </div>

          <div className={`${styles.partner} ${styles.safcom_logo}`}>
            <img className="" src={safcom} alt="mpesa logo" />
          </div>
        </Slider>
      </div>
    </div>
  );
};

export default Partners;
