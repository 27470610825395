import { useEffect, useState } from "react";
import axios from "../api/axios/axios";

export default function useFetchStates(countryId) {
  const [states, setStates] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (countryId) {
      (async function () {
        try {
          setLoading(true);
          const response = await axios.get(
            `/api/Utility/GetStates/${countryId}`
          );
          setStates(response.data);
        } catch (err) {
          setError(err);
          setStates([]);
        } finally {
          setLoading(false);
        }
      })();
    }
  }, [countryId]);

  return { states, setStates, error, loading };
}
