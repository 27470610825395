import React, { forwardRef, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useLocation } from "react-router";
import multiRacio from "../../../../assets/images/MultiRacial.png";
import Security from "../../../../assets/images/icons/cyber-security-02.png";
import Fast from "../../../../assets/images/icons/FAST-01.png";
import Affordable from "../../../../assets/images/icons/affordable-01.png";
import Ellipse from "../../../../assets/svgs/ellipse";
import styles from "./WhyUs.module.css";

const WhyUs = forwardRef((_, ref) => {
  const [licenseInfo, setLincenseInfo] = useState("");
  const { pathname } = useLocation();

  useEffect(() => {
    if (pathname.includes("ug")) {
      setLincenseInfo(" by Bank of Uganda");
    } else {
      setLincenseInfo(" the Central Bank of Kenya (CBK)");
    }
  }, [pathname]);

  return (
    <div className={`row ${styles.why_us}`} ref={ref}>
      <h1 className={styles.why_us__title}>Why Us</h1>

      <Row>
        <Col>
          <h3 className={styles.why_us__text}>
            Upesi is a homegrown African solution that enables efficient,
            reliable, and affordable global money transfer. We are licensed and
            regulated by {licenseInfo}
          </h3>
        </Col>
      </Row>

      <Row>
        <Col lg={6} className="position-relative">
          <Row className="mb-1">
            <div className="col-3">
              <img src={Fast} alt="fast icon"></img>
            </div>

            <div className="col">
              <div className="text-left">
                <h3 className={`${styles.why_us_primary_color}`}>
                  We are Safe
                </h3>
              </div>
              <div className="align-items-start text-left">
                <div className="ps-lg-3 ">
                  <Ellipse /> Licensed by the central Bank of Kenya
                </div>
                <div className="ps-lg-3">
                  <Ellipse /> Multi-factor Authentication
                </div>
                <div className="ps-lg-3">
                  <Ellipse /> Customer Data Privacy
                </div>
              </div>
            </div>
          </Row>

          <Row className="mb-1">
            <div className="col-3">
              <img src={Security} alt="security icon"></img>
            </div>

            <div className="col">
              <div className="text-left">
                <h3 className={`${styles.why_us_primary_color}`}>
                  We are Fast
                </h3>
              </div>
              <div className="text-left">
                <div className="ps-lg-3">
                  <Ellipse /> Service Available 24/7 from any device
                </div>
                <div className="ps-lg-3 text-left">
                  <Ellipse /> Not affected by weekends and public holidays
                </div>
                <div className="ps-lg-3 text-left">
                  <Ellipse /> Simplified registration and transactional
                  procedures
                </div>
              </div>
            </div>
          </Row>

          <Row>
            <div className="col-3">
              <img src={Affordable} alt="money icon"></img>
            </div>

            <div className="col">
              <div className="text-left">
                <h3 className={`${styles.why_us_primary_color}`}>
                  We are Affordable
                </h3>
              </div>
              <div className="text-left">
                <div className="ps-lg-3">
                  <Ellipse /> Competitive Exchange Rates
                </div>
                <div className="ps-lg-3">
                  <Ellipse /> Low Sending Fees
                </div>
                <div className="ps-lg-3">
                  <Ellipse /> No Beneficiary Charges
                </div>
              </div>
            </div>
          </Row>
        </Col>

        <Col lg={6} className="d-flex align-items-end">
          <div>
            <img src={multiRacio} alt="" />
          </div>
        </Col>
      </Row>
    </div>
  );
});

export default WhyUs;
