import React, { useContext, useState } from "react";

import useFetchCities from "../../../../hooks/useFetchCities";
import { FormContext } from "../../Beneficiary";
import LocationDetails from "./LocationDetails";
import { locationDetailsSchema } from "./formValidation";

function LocationDetailsContainer() {
  const {
    activeStepIndex,
    setActiveStepIndex,
    formData,
    activeServiceProvider,
    loadingActiveServiceProvider,
    apiData: { states },
  } = useContext(FormContext);

  const [stateCode, setStateCode] = useState("");

  const { countryName, countryID } = formData.deliveryMethod;

  const { cities, setCities } = useFetchCities(
    countryID,
    stateCode,
    activeServiceProvider
  );

  const handleState = (val, setFieldValue) => {
    setCities([]);
    setFieldValue("city", "");
    setStateCode(val.Id);
    setFieldValue("state", val.Name);
  };

  const handleCity = (val, setFieldValue) => {
    setFieldValue("city", val.Name);
  };

  const onSubmit = (values) => {
    let activeAddress = values.address;
    if (
      activeServiceProvider === "MASTERCARD" ||
      countryName === "CHINA" ||
      countryName === "JAPAN"
    ) {
      activeAddress = `${values.street}, ${values.addressLine}, ${values.city}, ${values.state}, ${values.zipCode}`;
    }else if(countryName ==='CANADA'){
      activeAddress = `${values.addressLine}, ${values.city}, ${values.state}, ${values.zipCode}`;
    }

    formData.setLocationDetails({
      ...formData.locationDetails,
      ...values,
      address: activeAddress,
    });
    setActiveStepIndex(activeStepIndex + 1);
  };

  const locationProps = {
    formData,
    handleState,
    handleCity,
    cities,
    states,
    onSubmit,
    activeServiceProvider,
    locationDetailsSchema: locationDetailsSchema({
      countryName,
      activeServiceProvider,
    }),
  };

  return (
    <>
      {loadingActiveServiceProvider ? (
        <div className="mainDiv">
          <div className="loading"></div>
        </div>
      ) : null}
      <LocationDetails locationProps={locationProps} />
    </>
  );
}

export default LocationDetailsContainer;
