import UpesiFormik from "../UpesiFormik";
import { useContext } from "react";
import { FormContext } from "../SendMoney";
import * as Yup from "yup";

export default function AccountsDetails() {
  const { setActiveStepIndex, formData, fields, onSubmit, handleValueChange } =
    useContext(FormContext);

  const initialStepData = {
    ...formData,
    isReceiverAccount: true,
    isBeneficiary: true,
  };

  const stepValidationSchema = () =>
    Yup.object().shape({
      beneficiary: Yup.string()?.when("isBeneficiary", {
        is: true,
        then: Yup.string().required("Select Beneficiary"),
        otherwise: Yup.string(),
      }),
    });

  const propsData = {
    setActiveStepIndex,
    formData,
    stepFields: fields["accountDetails"],
    initialStepData,
    onSubmit,
    handleValueChange,
    stepValidationSchema,
    formId: "account-details-info",
  };

  return <UpesiFormik props={propsData} />;
}
