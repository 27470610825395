import classNames from "classnames";
import { ErrorMessage, Form, Formik } from "formik";
import React from "react";
import DatePicker from "react-datepicker";
import Select from "react-select";
import ReactTooltip from "react-tooltip";

import { CustomStyle, defaultStyle } from "../../../../utils/styles";
import FloatingLabel from "../../../../tools/FloatingLabel";

function PersonalInfo({ personalInfoProps }) {
  const {
    activeServiceProvider,
    isLoading,
    formData,
    handleDocumentType,
    handleDocumentExpiryDate,
    documentTypes,
    onSubmit,
    personalInfoSchema,
    handleInputChange,
    countrySupportsRelation,
    location,
    paymentService,
  } = personalInfoProps;
  const { countryID } = formData.deliveryMethod;

  return (
    <Formik
      initialValues={{ ...formData.personalInfo }}
      validationSchema={personalInfoSchema}
      onSubmit={(val) => onSubmit(val)}
    >
      {({ handleChange, handleBlur, handleSubmit, setFieldValue, errors, touched, values }) => (
        <Form id="registration-form" className="row justify-content-center" onSubmit={handleSubmit}>
          <div className="h4 text-center step-title">Personal Details</div>
          <div className="form-group col-12 col-sm-8 col-lg-6 col-xl-4" id="custom_inut">
            <i
              data-tip
              data-for="help"
              style={{ padding: "2px 0px 0px 8px" }}
              className="fa fa-info-circle beneficiary-info-icon"
              aria-hidden="true"
            ></i>
            <ReactTooltip id="help" place="top" effect="solid">
              This field will help to uniquely identify beneficiaries.
            </ReactTooltip>
            <FloatingLabel label="Nick Name" value={values.nickName} />
            <input
              className={`form-control form-input ${errors.nickName && touched.nickName ? "form-error" : ""}`}
              value={values.nickName}
              type="text"
              name="nickName"
              onChange={(val) => handleInputChange(val, setFieldValue)}
              onBlur={handleBlur}
              placeholder="Nick Name"
              disabled={location?.state?.action === "EDIT_BENEFICIARY"}
            />
          </div>
          <div className="error-text col-12 text-center my-2">
            <ErrorMessage name="nickName" />
          </div>
          {(paymentService === "C2C" || countryID === 198) && (
            <>
              <div className="form-group col-12 col-sm-8 col-lg-6 col-xl-4">
                <FloatingLabel label="First Name" value={values.firstName} />
                <input
                  value={values.firstName}
                  type="text"
                  name="firstName"
                  onChange={(val) => handleInputChange(val, setFieldValue)}
                  onBlur={handleBlur}
                  className={`form-control form-input ${errors.firstName && touched.firstName ? "form-error" : ""}`}
                  placeholder="First Name"
                />
              </div>
              <div className="error-text col-12 text-center my-2">
                <ErrorMessage name="firstName" />
              </div>
              <div className="form-group col-12 col-sm-8 col-lg-6 col-xl-4">
                <FloatingLabel label="Middle Name" value={values.middleName} />
                <input
                  value={values.middleName}
                  type="text"
                  name="middleName"
                  onChange={(val) => handleInputChange(val, setFieldValue)}
                  onBlur={handleBlur}
                  className={`form-control form-input `}
                  placeholder="Middle Name"
                />
              </div>
              <div className="error-text col-12 text-center my-2">
                <ErrorMessage name="middleName" />
              </div>
              <div className="form-group col-12 col-sm-8 col-lg-6 col-xl-4">
                <FloatingLabel label="Last Name" value={values.lastName} />
                <input
                  value={values.lastName}
                  type="text"
                  name="lastName"
                  onChange={(val) => handleInputChange(val, setFieldValue)}
                  onBlur={handleBlur}
                  className={`form-control form-input`}
                  placeholder="Last Name"
                />
              </div>
              <div className="error-text col-12 text-center my-2">
                <ErrorMessage name="lastName" />
              </div>
            </>
          )}
          {countryID !== 198 && !countrySupportsRelation && (
            <>
              <div className="form-group col-12 col-sm-8 col-lg-6 col-xl-4 row">
                <FloatingLabel label="Phone Number" value={values.phoneNumber} />
                <div className="col-3 p-0" id="code-input-section">
                  <input
                    className="form-control form-input"
                    type="text"
                    disabled
                    name="phoneNumberCode"
                    value={values.phoneNumberCode}
                  />
                </div>
                <div className="col-9 p-0">
                  <input
                    className={`form-control form-input mobile-input  ${
                      errors.phoneNumber && touched.phoneNumber ? "form-error" : ""
                    }`}
                    value={values.phoneNumber}
                    type="number"
                    name="phoneNumber"
                    onChange={(val) => handleInputChange(val, setFieldValue)}
                    onBlur={handleBlur}
                    placeholder="Mobile number eg. 7XXXXXXXX"
                  />
                </div>
              </div>
              <div className="error-text  col-12 text-center my-2">
                <ErrorMessage name="phoneNumber" />
              </div>
            </>
          )}

          {(activeServiceProvider === "MASTERCARD" ||
            formData.deliveryMethod.countryName === "UNITED ARAB EMIRATES" ||
            formData.deliveryMethod.countryName === "PAKISTAN") &&
            documentTypes.length > 0 && (
              <>
                <div className="form-group col-12 col-sm-8 col-lg-6 col-xl-4">
                  <FloatingLabel label="Document Type" value={values.documentType} />
                  <Select
                    value={
                      values.documentType && {
                        name: values.documentType,
                        id: values.documentTypeID,
                      }
                    }
                    name="documentTypeID"
                    onChange={(val) => handleDocumentType(val, setFieldValue)}
                    options={documentTypes}
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.id}
                    placeholder="Select document type"
                    styles={errors.documentTypeID && touched.documentTypeID ? CustomStyle : defaultStyle}
                    noOptionsMessage={() => "Document type is loading..."}
                  />
                </div>
                <div className="error-text col-12 text-center my-2">
                  <ErrorMessage name="documentTypeID" />
                </div>
                <div className="form-group col-12 col-sm-8 col-lg-6 col-xl-4">
                  <FloatingLabel label="Document Number" value={values.idNumber} />
                  <input
                    value={values.idNumber}
                    type="text"
                    name="idNumber"
                    onChange={(val) => handleInputChange(val, setFieldValue)}
                    onBlur={handleBlur}
                    className={`form-control form-input ${errors.idNumber && touched.idNumber ? "form-error" : ""}`}
                    placeholder="Document Number"
                  />
                </div>
                <div className="error-text col-12 text-center my-2">
                  <ErrorMessage name="idNumber" />
                </div>
                <div className="form-group col-12 col-sm-8 col-lg-6 col-xl-4">
                  <FloatingLabel label="Document Expiry Date" value={values.documentExpiresOn} />
                  <DatePicker
                    name="documentExpiresOn"
                    value={values.documentExpiresOn}
                    showYearDropdown
                    showMonthDropdown
                    dropdownMode="select"
                    selected={values.documentExpiresOn}
                    onChange={(date) => handleDocumentExpiryDate(date, setFieldValue, values)}
                    onBlur={handleBlur}
                    placeholderText="Document Expiry Date"
                    // dateFormat="dd-MM-yyyy"
                    dateFormat="yyyy-MM-dd"
                    minDate={new Date()}
                    // minDate={yesterday()}
                    className={`form-control form-input ${
                      errors?.documentExpiresOn && touched?.documentExpiresOn ? "form-error" : ""
                    }`}
                  />
                </div>
                <div className="error-text col-12 text-center my-2">
                  <ErrorMessage name="documentExpiresOn" />
                </div>
              </>
            )}

          {/* india C2B bank fields */}
          {countryID === 198 && paymentService === "C2B" && (
            <>
              <div className="form-group col-12 col-sm-8 col-lg-6 col-xl-4">
                <FloatingLabel label="Email" value={values.email} />
                <input
                  value={values.email}
                  type="text"
                  name="email"
                  onChange={(val) => handleInputChange(val, setFieldValue)}
                  onBlur={handleBlur}
                  className={`form-control form-input`}
                  placeholder="Email"
                />
              </div>
              <div className="error-text col-12 text-center my-2">
                <ErrorMessage name="email" />
              </div>
              <div className="form-group col-12 col-sm-8 col-lg-6 col-xl-4">
                <FloatingLabel label="Business Registration Issuer" value={values.businessRegistrationIssuedBy} />
                <input
                  value={values.businessRegistrationIssuedBy}
                  type="text"
                  name="businessRegistrationIssuedBy"
                  onChange={(val) => handleInputChange(val, setFieldValue)}
                  onBlur={handleBlur}
                  className={`form-control form-input`}
                  placeholder="Business Registration Issuer"
                />
              </div>
              <div className="error-text col-12 text-center my-2">
                <ErrorMessage name="businessRegistrationIssuedBy" />
              </div>
              <div className="form-group col-12 col-sm-8 col-lg-6 col-xl-4">
                <FloatingLabel label="Type Of Business" value={values.typeOfBusiness} />
                <input
                  value={values.typeOfBusiness}
                  type="text"
                  name="typeOfBusiness"
                  onChange={(val) => handleInputChange(val, setFieldValue)}
                  onBlur={handleBlur}
                  className={`form-control form-input`}
                  placeholder="Type Of Business"
                />
              </div>
              <div className="error-text col-12 text-center my-2">
                <ErrorMessage name="typeOfBusiness" />
              </div>
              <div className="form-group col-12 col-sm-8 col-lg-6 col-xl-4">
                <FloatingLabel label="Regional Beneficiary Name" value={values.regionalBeneficiaryName} />
                <input
                  value={values.regionalBeneficiaryName}
                  type="text"
                  name="regionalBeneficiaryName"
                  onChange={(val) => handleInputChange(val, setFieldValue)}
                  onBlur={handleBlur}
                  className={`form-control form-input`}
                  placeholder="Regional Beneficiary Name"
                />
              </div>
              <div className="error-text col-12 text-center my-2">
                <ErrorMessage name="regionalBeneficiaryName" />
              </div>
              <div className="form-group col-12 col-sm-8 col-lg-6 col-xl-4">
                <FloatingLabel label="Business Description" value={values.businessDescription} />
                <textarea
                  value={values.businessDescription}
                  type="text"
                  name="businessDescription"
                  onChange={(val) => handleInputChange(val, setFieldValue)}
                  onBlur={handleBlur}
                  className={`form-control form-input`}
                  placeholder="Business Description"
                  rows="3"
                  cols="33"
                ></textarea>
              </div>
              <div className="error-text col-12 text-center my-2">
                <ErrorMessage name="businessDescription" />
              </div>
            </>
          )}
          <div className="col-12 col-sm-8 col-lg-6 col-xl-4">
            <button
              id="reg-btn"
              type="submit"
              disabled={isLoading ? true : false}
              className={"btn btn-orange w-100 " + classNames({ spin: isLoading }, "load-button")}
            >
              Submit
              <span className="spinner"></span>
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default PersonalInfo;
