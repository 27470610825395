import React from "react";
import { Row } from "react-bootstrap";
import ExchangeRateCard from "../ExchangeRate/ExchangeRateCard";
import styles from "./UGHeaderCover.module.css";
import DownLoadApp from "../DownLoadApp/DownloadApp";

const UGHeaderCover = () => {
  return (
    <Row className="align-items-center">
      <div className="col-12 col-md-6 col-lg-7">
        <div className="text-left">
          <h1 className={`${styles.cover_title__text} h1`}>
            Send Money Anywhere,
            <br /> Anytime
          </h1>
        </div>
          <DownLoadApp/>
      </div>
      <div className="col-12 col-md-6 col-lg-5 p-xl-3">
        <ExchangeRateCard />
      </div>
    </Row>
  );
};

export default UGHeaderCover;
