import { useEffect, useState } from "react";
import axios from "../api/axios/axios";

export default function useFetchReceiverDocumentTypes(body) {
  const [documentTypes, setDocumentTypes] = useState([]);
  const [loadingdocumentTypes, setLoadingDocumentTypes] = useState(false);
  const { type, countryID, paymentService, customerType, serviceTypeID } = body;

  // TODO: customertype change should affect paymentservice
  useEffect(() => {
    if (serviceTypeID && countryID && customerType) {
      setLoadingDocumentTypes(true);
      axios
        .post(`/api/Utility/GetReceiverDocumentTypes`, {
          type,
          countryID,
          paymentService,
          customerType,
          serviceTypeID,
        })
        .then((res) => {
          setLoadingDocumentTypes(false);
          setDocumentTypes(res.data);
        })
        .catch((err) => {
          setLoadingDocumentTypes(false);
          setDocumentTypes([]);
        });
    }
    return () => {
      setDocumentTypes([]);
    };
  }, [type, countryID, paymentService, customerType, serviceTypeID]);

  return { documentTypes,loadingdocumentTypes };
}
