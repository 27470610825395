import { ErrorMessage, Form, Formik } from "formik";
import React from "react";
import Select from "react-select";

import { bankDetailsSchema } from "./formValidation";
import { CustomStyle, defaultStyle } from "../../../../../utils/styles";
import FloatingLabel from "../../../../../tools/FloatingLabel";

function BankDetails({ props }) {
  const {
    isLoading,
    viewHiddenValue,
    toggleHiddenValue,
    formData,
    onSubmit,
    handleSwiftCode,
    handleBankName,
    handleBankBranches,
    bridgeBank,
    countryDeliveryOptionFields,

    bridgeBankBranches,
    bankList,
    banksBySwiftCode,
    swiftCodeCountryIds,
  } = props;

  const { countryID } = formData.deliveryMethod;
  //TODO: fix bank and branch conditions, refactor this implementation

  return (
    <>
      {isLoading ? (
        <div className="mainDiv">
          <div className="loading"></div>
        </div>
      ) : null}
      <Formik
        initialValues={{ ...formData.deliveryType.bankDetails }}
        validationSchema={bankDetailsSchema(countryDeliveryOptionFields)}
        onSubmit={(val) => onSubmit(val)}
      >
        {({ handleChange, handleBlur, handleSubmit, setFieldValue, errors, touched, values }) => (
          <Form
            id="registration-form"
            className="row justify-content-center"
            onSubmit={handleSubmit}
            autoComplete="off"
          >
            <div className="h3 text-center mb-2 step-title">Bank Details</div>
            {countryDeliveryOptionFields?.map(({ fieldName, formInputType, fieldOptions, index }) => {
              return (
                <div key={index} className="col-12 row justify-content-center mb-0 pb-0">
                  <div
                    className={`form-group col-12 col-sm-8 col-lg-6 col-xl-4 ${
                      fieldName === "bankMobileNumber" ? "row" : ""
                    }`}
                    id="account-input"
                  >
                    {formInputType === "Text" && (
                      <>
                        {fieldName === "swiftCode" && (
                          <>
                            <FloatingLabel label={fieldOptions.displayName} value={values.swiftCode} />
                            <input
                              autoComplete="new-text"
                              type="text"
                              name={fieldName}
                              value={values.swiftCode}
                              onChange={(val) => handleSwiftCode(val, setFieldValue)}
                              onBlur={handleBlur}
                              className={`form-control form-input ${
                                errors.swiftCode && touched.swiftCode ? "form-error" : ""
                              }`}
                              placeholder={fieldOptions.displayName}
                            />
                          </>
                        )}
                        {fieldName === "bankName" && (
                          <>
                            <FloatingLabel label="Bank Name" value={values.bankName} />
                            <input
                              autoComplete="new-text"
                              type="text"
                              name={fieldName}
                              value={values.bankName}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              className={`form-control form-input ${
                                errors.bankName && touched.bankName ? "form-error" : ""
                              }`}
                              placeholder={fieldOptions.displayName}
                            />
                          </>
                        )}

                        {fieldName === "bankBranch" && countryID !== 136 && (
                          <>
                            <FloatingLabel label="Branch Name" value={values.bankBranch} />
                            <input
                              autoComplete="new-text"
                              type="text"
                              name={fieldName}
                              value={values.bankBranch}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              className={`form-control form-input ${
                                errors.bankBranch && touched.bankBranch ? "form-error" : ""
                              }`}
                              placeholder={fieldOptions.displayName}
                            />
                          </>
                        )}
                        {fieldName === "bankBranchCode" && (
                          <>
                            {/* 136=canada */}
                            <FloatingLabel label="Bank Sub Code" value={values.bankBranchCode} />
                            <input
                              autoComplete="new-text"
                              type="text"
                              name="bankBranchCode"
                              value={values.bankBranchCode}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              className={`form-control form-input ${
                                errors.bankBranchCode && touched.bankBranchCode ? "form-error" : ""
                              }`}
                              placeholder="Bank Sub Code"
                              // placeholder={fieldOptions.displayName}
                            />
                          </>
                        )}
                        {fieldName === "accountName" && (
                          <>
                            <FloatingLabel label="Account Name" value={values.accountName} />
                            <input
                              autoComplete="new-text"
                              type="text"
                              name={fieldName}
                              value={values.accountName}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              className={`form-control form-input ${
                                errors.accountName && touched.accountName ? "form-error" : ""
                              }`}
                              placeholder={fieldOptions.displayName}
                            />
                          </>
                        )}
                        {(fieldName === "accountNumber" || fieldName === "sortCode") && (
                          <>
                            <FloatingLabel
                              label={fieldName === "accountNumber" ? "Account Number" : "Sort Code"}
                              value={fieldName === "accountNumber" ? values.accountNumber : values.sortCode}
                            />
                            <input
                              autoComplete="new-text"
                              type="text"
                              name={fieldName}
                              value={fieldName === "accountNumber" ? values.accountNumber : values.sortCode}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              className={`form-control form-input ${
                                fieldName === "accountNumber"
                                  ? errors.accountNumber && touched.accountNumber
                                    ? "form-error"
                                    : ""
                                  : errors.sortCode && touched.sortCode
                                  ? "form-error"
                                  : ""
                              }`}
                              placeholder={fieldOptions.displayName}
                            />
                          </>
                        )}
                        {(fieldName === "confirmAccountNumber" || fieldName === "confirmSortCode") && (
                          <>
                            <FloatingLabel
                              label={
                                fieldName === "confirmAccountNumber" ? "Confirm Account Number" : "Confirm Sort Code"
                              }
                              value={
                                fieldName === "accountNumber" ? values.confirmAccountNumber : values.confirmSortCode
                              }
                            />
                            <input
                              autoComplete="new-text"
                              type="text"
                              name={fieldName}
                              value={
                                fieldName === "accountNumber" ? values.confirmAccountNumber : values.confirmSortCode
                              }
                              onChange={handleChange}
                              onBlur={handleBlur}
                              className={`form-control form-input ${
                                fieldName === "confirmAccountNumber"
                                  ? errors.confirmAccountNumber && touched.confirmAccountNumber
                                    ? "form-error"
                                    : ""
                                  : errors.confirmSortCode && touched.confirmSortCode
                                  ? "form-error"
                                  : ""
                              }`}
                              placeholder={fieldOptions.displayName}
                            />
                          </>
                        )}

                        {fieldName === "bankMobileNumber" && (
                          <>
                            <FloatingLabel label="Mobile Number" value={values.bankMobileNumber} />
                            <div className="col-3 p-0" id="code-input-section">
                              <input
                                className="form-control form-input"
                                type="text"
                                disabled
                                name="bankMobileCode"
                                value={values.bankMobileCode}
                              />
                            </div>
                            <div className="col-9 p-0">
                              <input
                                value={values.bankMobileNumber}
                                type="number"
                                name={fieldName}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                placeholder="XXXXXXXXX"
                                className={`form-control form-input mobile-input  ${
                                  errors.bankMobileNumber && touched.bankMobileNumber ? "form-error" : ""
                                }`}
                              />
                            </div>
                          </>
                        )}
                      </>
                    )}
                    {formInputType === "Select" && (
                      <>
                        {fieldName === "bankName" && (
                          <>
                            {banksBySwiftCode?.length > 0 && swiftCodeCountryIds.includes(countryID) && (
                              <>
                                <FloatingLabel label="Bank Name" value={values.bankName} />
                                <Select
                                  name="bankName"
                                  value={
                                    values.bankName && {
                                      bank_name: values.bankName,
                                    }
                                  }
                                  onChange={(val) => handleBankName(val, setFieldValue)}
                                  options={banksBySwiftCode}
                                  getOptionLabel={(option) => option.bank_name}
                                  getOptionValue={(option) => option}
                                  placeholder="Bank Name"
                                  styles={errors.bankName && touched.bankName ? CustomStyle : defaultStyle}
                                  noOptionsMessage={() => "Bank name is loading..."}
                                />
                              </>
                            )}
                            {bankList?.length > 0 && countryID === 198 && (
                              <>
                                <FloatingLabel label="Bank Name" value={values.bankName} />
                                <Select
                                  name="bankName"
                                  value={
                                    values.bankName && {
                                      fullSearchDetails: values.bankName,
                                    }
                                  }
                                  onChange={(val) => handleBankName(val, setFieldValue)}
                                  options={bankList}
                                  getOptionLabel={(option) => option.fullSearchDetails}
                                  getOptionValue={(option) => option}
                                  placeholder="Bank Name"
                                  styles={errors.bankName && touched.bankName ? CustomStyle : defaultStyle}
                                  noOptionsMessage={() => "Bank name is loading..."}
                                />
                              </>
                            )}
                            {!swiftCodeCountryIds.includes(countryID) &&
                              countryID !== 198 &&
                              bridgeBank?.length > 0 && (
                                <>
                                  <FloatingLabel label="Bank Name" value={values.bankName} />
                                  <Select
                                    name="bankName"
                                    value={
                                      values.bankName && {
                                        bank_name: values.bankName,
                                      }
                                    }
                                    onChange={(val) => handleBankName(val, setFieldValue)}
                                    options={bridgeBank}
                                    getOptionLabel={(option) => option.bank_name}
                                    getOptionValue={(option) => option}
                                    placeholder="Bank Name"
                                    styles={errors.bankName && touched.bankName ? CustomStyle : defaultStyle}
                                    noOptionsMessage={() => "Bank name is loading..."}
                                  />
                                </>
                              )}
                          </>
                        )}

                        {fieldName === "bankBranch" && (
                          <>
                            {(bankList.length > 0 || bridgeBank.length > 0) && values.swiftCode ? (
                              <>
                                <FloatingLabel label="Branch Name" value={values.bankBranch} />
                                <input
                                  autoComplete="new-text"
                                  type="text"
                                  name={fieldName}
                                  value={values.bankBranch}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  className={`form-control form-input ${
                                    errors.bankBranch && touched.bankBranch ? "form-error" : ""
                                  }`}
                                  placeholder={fieldOptions.displayName}
                                  disabled
                                />
                              </>
                            ) : (
                              <>
                                <FloatingLabel label="Branch Name" value={values.bankBranch} />
                                <Select
                                  name="bankBranch"
                                  value={
                                    values.bankBranch && {
                                      branch_name: values.bankBranch,
                                    }
                                  }
                                  onChange={(val) => handleBankBranches(val, setFieldValue)}
                                  options={bridgeBankBranches}
                                  getOptionLabel={(option) => option.branch_name}
                                  getOptionValue={(option) => option}
                                  placeholder="Branch Name"
                                  styles={errors.bankBranch && touched.bankBranch ? CustomStyle : defaultStyle}
                                  noOptionsMessage={() => "Branch name is loading..."}
                                />
                              </>
                            )}
                          </>
                        )}
                      </>
                    )}
                    {formInputType === "Password" && (
                      <>
                        {fieldName === "accountNumber" && (
                          <>
                            <FloatingLabel label={fieldOptions.displayName} value={values.accountNumber} />

                            <input
                              autoComplete="new-password"
                              type="text"
                              name={fieldName}
                              value={values.accountNumber}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              className={`form-control form-input ${
                                errors.accountNumber && touched.accountNumber ? "form-error" : ""
                              }`}
                              placeholder={fieldOptions.displayName}
                              style={{ WebkitTextSecurity: `${viewHiddenValue.firtValue ? "" : "disc"}` }}
                            />
                            <i
                              className={`fa fa-eye icon account-password-icon ${
                                viewHiddenValue.firtValue ? "account-password-gray" : ""
                              }`}
                              id={`${values.accountNumber ? "icon-label" : ""}`}
                              onClick={() => toggleHiddenValue("FIRST")}
                            ></i>
                          </>
                        )}

                        {fieldName === "confirmAccountNumber" && (
                          <>
                            <FloatingLabel label={fieldOptions.displayName} value={values.confirmAccountNumber} />
                            <input
                              autoComplete="new-password"
                              type="text"
                              name={fieldName}
                              value={values.confirmAccountNumber}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              className={`form-control form-input input-password ${
                                errors.confirmAccountNumber && touched.confirmAccountNumber ? "form-error" : ""
                              }`}
                              placeholder={fieldOptions.displayName}
                              style={{ WebkitTextSecurity: `${viewHiddenValue.secondValue ? "" : "disc"}` }}
                            />
                            <i
                              className={`fa fa-eye icon account-password-icon ${
                                viewHiddenValue.secondValue ? "account-password-gray" : ""
                              } `}
                              id={`${values.confirmAccountNumber ? "icon-label" : ""}`}
                              onClick={() => toggleHiddenValue("SECOND")}
                            ></i>
                          </>
                        )}
                      </>
                    )}
                  </div>
                  <div className="error-text text-center my-2">
                    <ErrorMessage name={fieldName} />
                  </div>
                </div>
              );
            })}
            <div className="col-12 col-sm-8 col-lg-6 col-xl-4">
              <button className="btn btn-orange w-100" type="submit">
                Next
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
}

export default BankDetails;
