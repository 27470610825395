/* eslint-disable jsx-a11y/heading-has-content */
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const CTA = () => {
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate("/login");
  };

  const onScroll = () => {
    const element = document.querySelector("#ctaSendMoney");
    const position = element.getBoundingClientRect();

    if (position.top >= 0 && position.bottom <= window.innerHeight) {
      const element = document.getElementById("markets");
      const element1 = document.getElementById("transactions");
      const element2 = document.getElementById("moneyTransafered");
      const element3 = document.getElementById("happyUsers");

      animateValue(element, 10, 73, 2000, "", "");
      animateValue(element1, 0, 3, 2000, "", "M");
      animateDecimalValue(element2, 0.1, 1.5, 2000, "$", "B");
      animateValue(element3, 100, 500, 2000, "", "K+");
    }
  };

  function animateValue(obj, start, end, duration, startString, endString) {
    let startTimestamp = null;
    const step = (timestamp) => {
      if (!startTimestamp) startTimestamp = timestamp;
      const progress = Math.min((timestamp - startTimestamp) / duration, 1);
      obj.innerHTML =
        startString + Math.floor(progress * (end - start) + start) + endString;
      if (progress < 1) {
        window.requestAnimationFrame(step);
      }
    };
    window.requestAnimationFrame(step);
  }

  function animateDecimalValue(
    obj,
    start,
    end,
    duration,
    startString,
    endString
  ) {
    let num = start;
    const interval = setInterval(() => {
      num += 0.07;
      obj.textContent = startString + num.toFixed(1) + endString;
      if (num >= end) clearInterval(interval);
    }, 100);
  }

  useEffect(() => {
    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={`row`}>
      <div className={`row p-5 mt-0`}>
        {/* Card Beging */}
        <div
          id="ctaSendMoney"
          className="col-12 col-md-4 col-lg-4 col-xl-4 card p-1 card-props"
        >
          {/* Top Row */}
          <div className="row border-bottom h-50">
            <div className="col border-end p-lg-2 p-sm-1  w-50">
              <div className="color-dark-blue">
                <h1 id="transactions"></h1>
              </div>
              <div>
                <h4 className="color-fade">Transactions</h4>
              </div>
            </div>

            <div className="col p-lg-2 p-sm-1 w-50">
              <div className="color-dark-blue">
                <h1 id="moneyTransafered"></h1>
              </div>
              <div>
                <h4 className="color-fade">Money Transfered</h4>
              </div>
            </div>
          </div>

          {/* Bottom row */}
          <div className="row h-50">
            <div className="col border-end p-lg-2  w-50">
              <div className="color-dark-blue">
                <h1 id="happyUsers"></h1>
              </div>
              <div>
                <h4 className="color-fade">Happy users</h4>
              </div>
            </div>

            <div className="col p-lg-2 w-50">
              <div className="color-dark-blue">
                <h1 id="markets"></h1>
              </div>
              <div>
                <h4 className="color-fade">Markets</h4>
              </div>
            </div>
          </div>
        </div>
        {/* Card End */}

        {/* Send Money across -- start */}

        <div className="col-12 col-md-8 col-lg-8 col-xl-8 p-lg-3">
          <div>
            <h1>Send Money across Africa and Beyond</h1>
          </div>
          <div className="pt-lg-1">
            Enjoy exceptional money transfer services globally with Upesi Money
            transfer. We give you swift, seamless and secure transfers with a
            variety of services from bank transfers to mobile money.
          </div>
          <div className="pt-3">
            <button
              type="button"
              onClick={handleNavigate}
              className="btn btn-lg primary-color-bg"
            >
              <h2>Get Started</h2>
            </button>
          </div>
        </div>
      </div>

      {/* Send Money across -- End */}
    </div>
  );
};

export default CTA;
