import React,{useState} from "react";
import { Container } from "react-bootstrap";
import useWindowDimensions from "../../../../hooks/useWindowDimensions";
import CustomerJourney from "../../Company/CustomerJourney/CustomerJourney";
import BToB from "../../Components/BToB/BToB";
import CTA from "../../Components/CTA/CTA";
import Countries from "../../Components/Countries/Countries";
import HeaderCover from "../../Components/HeaderCover/HeaderCover";
import Partners from "../../Components/Partners/Partners";
import SendMoney from "../../Components/SendMoney/SendMoney";
import WhyUs from "../../Components/WhyUs/WhyUs";
import "../Kenya/Kenya.css";
import "../../Components/AdvertPopup/advert_popup.jsx"
import FloatingWindow from "../../Components/AdvertPopup/advert_popup.jsx";
const Kenya = () => {
  const { width } = useWindowDimensions();
  const [showPopup, setShowPopup] = useState(true)
  const closePopup = () => setShowPopup(false)

  return (
    <Container
      fluid
      className="px-sm-0 px-md-0 px-lg-5 align-items-center flex-column"
    >
      <Container
        className={`container justify-content-center ${
          width > 1200 ? "w-75" : ""
        }`}
      >
        <div className="mb-5">
          <HeaderCover />
        </div>
          {showPopup && (
          <FloatingWindow onClose={closePopup}>
            </FloatingWindow>
      )}
        <BToB />

        <CustomerJourney />
        <WhyUs />
        <div className="light_blue">
          <CTA />
        </div>
        <SendMoney />
        <Countries />
        <Partners />
      </Container>
    </Container>
  );
};

export default Kenya;
