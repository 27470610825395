import { useEffect, useState } from "react";
import axios from "../api/axios/axios";

export default function useFetchAllCountriesData(params) {
  const [countriesData, setCountriesData] = useState([]);

  useEffect(() => {
    axios
      .get(`/api/Utility/GetAllCountriesData?${params}`)
      .then((res) => {
        let mappedData = res.data.result.map((data) => ({
          ...data,
          ...data.countryDetails,
        }));

        setCountriesData(mappedData);
      })
      .catch((err) => {
        setCountriesData(null);
      });
  }, [params]);

  return { countriesData, setCountriesData };
}
