import * as Yup from "yup";

export const deliveryMethodSchema = (countrySupportsRelation) =>
  Yup.object().shape({
    countryName: Yup.string().required("Select your country"),
    deliveryOption: Yup.string().required("Select your delivery option"),
    beneficiaryType: Yup.string().required("Select your beneficiary type"),
    relationShip: countrySupportsRelation
      ? Yup.string().required("Select your beneficiary relation")
      : Yup.string().nullable(),
  });
