import moment from "moment";
import classNames from "classnames";

import paymentIcon from "../../../assets/images/icons/add-payment-icon.png";
import React, { useContext, useState } from "react";
import { FormContext } from "../SendMoney";
import { NumericFormat } from "react-number-format";
import BackModal from "../../../components/BackModal";

export default function SendMoneyTransactionReview() {
  const {
    formData,
    beneficiaryBankDetails,
    beneficiaryMobileDetails,
    createTransaction,
    isLoading,
    cancelTransaction,
  } = useContext(FormContext);
  const [modal, setModal] = useState(false);

  const openModal = () => {
    setModal(true);
  };

  const closeModal = () => {
    setModal(false);
  };

  return (
    <>
      {modal ? (
        <BackModal show={modal} close={closeModal} delete={cancelTransaction} />
      ) : null}
      <div
        className="beneficiary-details-wrapper p-0 "
        style={{
          backgroundColor: "rgb(227, 227, 227)",
        }}
      >
        <div className="send-total-wrapper mob-padding-remove custom-grid-size px-0 pt-0">
          <div className="w-50 mr-0 pr-0">
            {/* Bank Deposit */}

            <div className="payment-details-add">
              <img src={paymentIcon} alt="payment icon" />
              <h2 className="mb-0">Receiver Details</h2>
            </div>
            <div className="send-fees  py-1">
              <ol>
                <li>
                  <h3 className="mb-0">Sending To</h3>
                  <p>{formData?.beneficiary}</p>
                </li>
                <li>
                  <h3 className="mb-0">Nick Name</h3>
                  <p>{formData?.beneficiaryObject.nickName}</p>
                </li>
                <li>
                  <h3 className="mb-0">Receiving Country</h3>
                  <p>{formData?.receivingCountry}</p>
                </li>
                <li>
                  <h3 className="mb-0">Purpose of Sending</h3>
                  <p>{formData.purposeOfSending}</p>
                </li>

                <li>
                  <h3 className="mb-0">Delivery Option</h3>
                  <p>{formData.deliveryOption}</p>
                </li>

                {/* BANK DETAILS */}
                {formData?.deliveryOption === "Bank Deposit" && (
                  <>
                    {formData.receivingCountry === "CHINA" ||
                    formData.receivingCountry === "CANADA" ||
                    formData.receivingCountry === "UNITED STATES OF AMERICA" ||
                    formData.receivingCountry ===
                      "UNITED KINGDOM OF GREAT BRITAIN AND NORTHERN IRELAND" ? null : (
                      <li>
                        <h3 className="mb-0">Bank Account Details</h3>
                        <p>{beneficiaryBankDetails?.bankName}</p>
                      </li>
                    )}
                    <li>
                      <h3 className="mb-0">
                        {formData?.receivingCountry === "PAKISTAN"
                          ? "IBAN Number"
                          : formData?.receivingCountry === "CANADA" ||
                            formData?.receivingCountry ===
                              "UNITED STATES OF AMERICA"
                          ? "Routing Number / Account Number"
                          : formData?.receivingCountry === "CHINA"
                          ? "Card Number"
                          : formData?.receivingCountry ===
                            "UNITED KINGDOM OF GREAT BRITAIN AND NORTHERN IRELAND"
                          ? null
                          : "Bank Account Number"}
                      </h3>
                      <p>{formData?.receivingCountry}</p>
                    </li>

                    {formData?.receivingCountry ===
                    "UNITED KINGDOM OF GREAT BRITAIN AND NORTHERN IRELAND" ? (
                      <>
                        <li>
                          <h3 className="mb-0">Account Number</h3>
                          <p>{beneficiaryBankDetails?.accountNumber}</p>
                        </li>
                        <li>
                          <h3 className="mb-0">Sort Code</h3>
                          <p>{beneficiaryBankDetails?.sortCode}</p>
                        </li>
                      </>
                    ) : null}

                    {formData?.receivingCountry === "CHINA" ||
                    formData?.receivingCountry === "CANADA" ||
                    formData?.receivingCountry === "UNITED STATES OF AMERICA" ||
                    formData?.receivingCountry ===
                      "UNITED KINGDOM OF GREAT BRITAIN AND NORTHERN IRELAND" ? null : (
                      <li>
                        <h3 className="mb-0">Branch</h3>
                        <p>{beneficiaryBankDetails?.bankBranch}</p>
                      </li>
                    )}
                  </>
                )}
                {/* Mobile DETAILS */}

                {formData?.deliveryOption === "Mobile Money" && (
                  <li>
                    <h3>Mobile Wallet Number</h3>
                    <p>{beneficiaryMobileDetails.mobileNumber}</p>
                  </li>
                )}

                {/* Cash PICKUP DETAILS */}
                {formData?.deliveryOption === "Cash Pickup" && (
                  <li>
                    <h3>Pickup Location</h3>
                    <p>{formData?.cashPickupLocation}</p>
                  </li>
                )}
              </ol>
            </div>
          </div>

          <div className="w-50">
            <div className="payment-details-add">
              <img src={paymentIcon} alt="icon" />
              <h2 className="mb-0">Transaction Details</h2>
            </div>
            <div className="send-fees py-1">
              <ol>
                <li>
                  <h3 className="mb-0">Sending Amount</h3>
                  <p>

                    <NumericFormat
                      prefix={formData?.senderCurrency}
                      thousandSeparator={true}
                      displayType="text"
                      value={
                        formData?.senderAmount -
                        formData?.fees -
                        formData?.exciseDuty
                      }
                    />
                  </p>
                </li>
                <li>
                  <h3 className="mb-0">Exchange Rate</h3>
                  <p>
                    1 {formData?.senderCurrency} = {formData?.exchangeRate}{" "}
                    {formData?.receiverCurrency}
                  </p>
                </li>
                <li>
                  <h3 className="mb-0">Fees</h3>
                  <p>
                    <NumericFormat
                      prefix={formData?.senderCurrency}
                      thousandSeparator={true}
                      displayType="text"
                      value={formData?.fees}
                    />
                  </p>
                </li>
                <li>
                  <h3 className="mb-0">Excise Duty</h3>
                  <p>
                    {formData?.senderCurrency} {formData.exciseDuty}
                  </p>
                </li>
                <li>
                  <h3 className="mb-0">Amount to Pay</h3>
                  <p>
                    <NumericFormat
                      prefix={formData?.senderCurrency}
                      thousandSeparator={true}
                      displayType="text"
                      value={formData?.senderAmount}
                    />
                  </p>
                </li>
                <li>
                  <h3 className="mb-0">Tentative Receiving Amount</h3>

                  <p>
                    <NumericFormat
                      className={"mx-1"}
                      prefix={formData?.receiverCurrency}
                      thousandSeparator={true}
                      displayType="text"
                      value={formData?.receiverAmount}
                    />
                  </p>
                </li>
                <li>
                  <h3 className="mb-0">Exchange Rate As On</h3>
                  <p>{moment().format("DD/MM/YYYY")}</p>
                </li>
              </ol>
            </div>
          </div>
        </div>
        <div className="padding-x steps-inputs-column1 pb-1">
          <button
            disabled={isLoading}
            type="button"
            className="btn bg-danger py-2"
            style={{ marginRight: "1rem" }}
            onClick={openModal}
          >
            Cancel
            <span className="spinner"></span>
          </button>
          <button
            disabled={isLoading}
            className={
              "py-2 btn send-money-btn px-5 " +
              classNames({ spin: isLoading }, "load-button")
            }
            type="button"
            onClick={createTransaction}
          >
            Send
            <span className="spinner send-money"></span>
          </button>
        </div>
      </div>
    </>
  );
}
