import React from "react";
import ExchangeRateCard from "../../Components/ExchangeRate/ExchangeRateCard";
import "./CashPickup.css";
import { Container } from "react-bootstrap";
import cashPickupimg from "../../../../assets/images/cash-pick-up.jpg";
import useWindowDimensions from "../../../../hooks/useWindowDimensions";

const CashPickup = () => {
  const { width } = useWindowDimensions();
  return (
    <div className="container-fluid px-0" style={{ display: "flex", flexDirection: "column" }}>
      <div className="cashpickup-banner">
        <img src={cashPickupimg} alt="cash"></img>
      </div>

      <Container className={`container justify-content-center ${width > 820 ? "w-75" : ""}`}>
        <div className="row pt-5">
          <div className="col-12 col-md-6 col-lg-6">
            <div className="row flex-direction-column text-start">
              <div className="col  w-75" id="swift-secureMoney">
                <h1>Swift Secure Money Transfers</h1>
              </div>

              <div className="col pt-3" id="swift-MobileMoney">
                <div className="">
                  <h1 className="mobile-money">Cash Pickup</h1>
                </div>
                <div className="" id="mobile-money-txt">
                 
                  Send cash to friends and family with Upesi Money Transfer. 
                  <br />
                  The recipient can Pick up cash in most countries from our numerous cash pickup locations.
                  <br></br>
                  <br />
                  Enjoy a simple money transfer process, fast service, and low fees.
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-6 px-5 py-5">
            <ExchangeRateCard />
          </div>
        </div>
      </Container>
    </div>
  );
};

export default CashPickup;
