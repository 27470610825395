import { ErrorMessage, Form, Formik } from "formik";
import React from "react";
import Select from "react-select";
import ReactTooltip from "react-tooltip";

import { CustomStyle, defaultStyle } from "../../../../utils/styles";
import FloatingLabel from "../../../../tools/FloatingLabel";

function LocationDetails({ locationProps }) {
  const { formData, handleState, handleCity, cities, states, onSubmit, activeServiceProvider, locationDetailsSchema } =
    locationProps;
  const { countryName, countryID } = formData.deliveryMethod;
  return (
    <Formik
      initialValues={{ ...formData.locationDetails }}
      validationSchema={locationDetailsSchema}
      onSubmit={(val) => onSubmit(val)}
    >
      {({ handleChange, handleBlur, handleSubmit, setFieldValue, errors, touched, values }) => (
        <Form id="registration-form" className="row justify-content-center" onSubmit={handleSubmit}>
          <div className="h4 text-center mt-2 step-title">Location Details</div>
          {activeServiceProvider === "MASTERCARD" || countryName === "PAKISTAN" || states?.length > 0 ? (
            <>
              <div className="form-group col-12 col-sm-8 col-lg-6 col-xl-4">
                {/* canada = 136 */}
                <FloatingLabel label={countryID === 136 ? "State/Province" : "State"} value={values.state} />
                <Select
                  name="state"
                  value={values.state && { Name: values.state }}
                  onChange={(val) => handleState(val, setFieldValue)}
                  options={states}
                  getOptionLabel={(option) => option.Name}
                  getOptionValue={(option) => option.Name}
                  placeholder="Select State"
                  onBlur={handleBlur}
                  styles={errors.state && touched.state ? CustomStyle : defaultStyle}
                  noOptionsMessage={() => "State is loading..."}
                />
              </div>
              <div className="error-text col-12 text-center my-2">
                <ErrorMessage name="state" />
              </div>
              <div className="form-group col-12 col-sm-8 col-lg-6 col-xl-4">
                <FloatingLabel label="City" value={values.city} />
                <Select
                  name="city"
                  value={values.city && { Name: values.city }}
                  onChange={(val) => handleCity(val, setFieldValue)}
                  options={cities}
                  getOptionLabel={(option) => option.Name}
                  getOptionValue={(option) => option.Name}
                  placeholder="Select City"
                  onBlur={handleBlur}
                  styles={errors.city && touched.city ? CustomStyle : defaultStyle}
                  noOptionsMessage={() => "City is loading..."}
                />
              </div>
              <div className="error-text col-12 text-center my-2">
                <ErrorMessage name="city" />
              </div>
            </>
          ) : (
            <>
              <div className="form-group col-12 col-sm-8 col-lg-6 col-xl-4">
                <FloatingLabel label="State" value={values.state} />
                <input
                  type="text"
                  name="state"
                  value={values.state}
                  placeholder="State"
                  className={`form-control form-input ${errors.state && touched.state ? "form-error" : ""}`}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </div>
              <div className="error-text col-12 text-center my-2">
                <ErrorMessage name="state" />
              </div>
              <div className="form-group col-12 col-sm-8 col-lg-6 col-xl-4">
                <FloatingLabel label="City" value={values.city} />
                <input
                  type="text"
                  name="city"
                  value={values.city}
                  placeholder="City"
                  className={`form-control form-input ${errors.city && touched.city ? "form-error" : ""}`}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </div>
              <div className="error-text col-12 text-center my-2">
                <ErrorMessage name="city" />
              </div>
            </>
          )}

          {/* canada = 136 */}
          {countryName === "CHINA" ||
          countryName === "JAPAN" ||
          countryName === "PAKISTAN" ||
          activeServiceProvider === "MASTERCARD" ||
          countryID === 136 ? (
            <>
              <div className="form-group col-12 col-sm-8 col-lg-6 col-xl-4 " id="custom_inut">
                <i
                  data-tip
                  data-for="addressLine"
                  style={{ padding: "2px 0px 0px 8px" }}
                  className="fa fa-info-circle beneficiary-info-icon"
                  aria-hidden="true"
                ></i>
                <ReactTooltip id="addressLine" place="top" effect="solid">
                  Building/landmark e.g Morningside park office/Bank Town
                </ReactTooltip>
                <FloatingLabel label="Address Line" value={values.addressLine} />
                <input
                  type="text"
                  name="addressLine"
                  value={values.addressLine}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={`form-control form-input ${errors.addressLine && touched.addressLine ? "form-error" : ""}`}
                  placeholder={countryID === 136 ? "Address Line 1" : "Suburb/Address Line"}
                />
              </div>
              <div className="error-text col-12 text-center my-2">
                <ErrorMessage name="addressLine" />
              </div>

              {countryID !== 136 && (
                <>
                  <div className="form-group col-12 col-sm-8 col-lg-6 col-xl-4" id="custom_inut">
                    <i
                      data-tip
                      data-for="street"
                      style={{ padding: "2px 0px 0px 8px" }}
                      className="fa fa-info-circle beneficiary-info-icon"
                      aria-hidden="true"
                    ></i>
                    <ReactTooltip id="street" place="top" effect="solid">
                      Street/road e.g Ngong RD/109 Meredith Street
                    </ReactTooltip>
                    <FloatingLabel label="Address Street" value={values.street} />
                    <input
                      type="text"
                      name="street"
                      placeholder="Street Number & Street Name/Address Street"
                      value={values.street}
                      className={`form-control form-input ${errors.street && touched.street ? "form-error" : ""}`}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>
                  <div className="error-text col-12 text-center my-2">
                    <ErrorMessage name="street" />
                  </div>
                </>
              )}
              <div className="form-group col-12 col-sm-8 col-lg-6 col-xl-4">
                {/* canada = 136 */}
                <FloatingLabel label={countryID === 136 ? "Postal Code" : "Zip Code"} value={values.zipCode} />
                <input
                  type="text"
                  name="zipCode"
                  value={values.zipCode}
                  placeholder="Zip Code e.g 2200"
                  className={`form-control form-input ${errors.zipCode && touched.zipCode ? "form-error" : ""}`}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </div>
              <div className="error-text col-12 text-center my-2">
                <ErrorMessage name="zipCode" />
              </div>
            </>
          ) : (
            <>
              <div className="form-group col-12 col-sm-8 col-lg-6 col-xl-4">
                <FloatingLabel label="Address" value={values.address} />
                <input
                  type="text"
                  name="address"
                  value={values.address}
                  placeholder="address"
                  className={`form-control form-input ${errors.address && touched.address ? "form-error" : ""}`}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </div>
              <div className="error-text col-12 text-center my-2">
                <ErrorMessage name="address" />
              </div>
            </>
          )}
          <div className="col-12 col-sm-8 col-lg-6 col-xl-4">
            <button className="btn btn-orange w-100" type="submit">
              Next
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default LocationDetails;
