export const renderPageNumbers = ({
  totalPages,
  currentPage,
  handlePageChange,
}) => {
  const pageNumbers = [];
  const totalPagesToShow = 5; // Number of page numbers to show

  if (totalPages <= totalPagesToShow) {
    // If there are fewer pages than the total pages to show, display all page numbers
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(i);
    }
  } else {
    // Display the first 3 page numbers
    for (let i = 1; i <= 3; i++) {
      pageNumbers.push(i);
    }

    if (currentPage <= 3) {
      // If the current page is close to the beginning, show a spread of pages
      for (let i = 4; i <= Math.min(5, totalPages - 1); i++) {
        pageNumbers.push(i);
      }
      if (totalPages > 5) {
        pageNumbers.push("...");
        pageNumbers.push(totalPages);
      }
    } else if (currentPage >= totalPages - 2) {
      // If the current page is close to the end, show a spread of pages
      if (totalPages - 3 > 3) {
        pageNumbers.push("...");
      }
      for (let i = Math.max(totalPages - 3, 4); i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      // If the current page is in the middle, show a spread of pages around it
      if (currentPage - 1 > 4) {
        pageNumbers.push("...");
      }
      for (
        let i = Math.max(currentPage - 1, 4);
        i <= Math.min(currentPage + 1, totalPages - 1);
        i++
      ) {
        pageNumbers.push(i);
      }
      if (totalPages - currentPage > 2) {
        pageNumbers.push("...");
      }
      pageNumbers.push(totalPages);
    }
  }

  return pageNumbers.map((page, index) => (
    <button
      key={index}
      className={`page-button ${currentPage === page ? "active" : ""}`}
      onClick={() => handlePageChange(page)}
    >
      {page}
    </button>
  ));
};
