import { useEffect, useState } from "react";
import axios from "../api/axios/axios";

export default function useFetchBeneficiaryMobileWalletDetails({
  beneficiaryID,
  deliveryOptionID,
}) {
  const [beneficiaryMobileDetails, setBeneficiaryMobileDetails] =
    useState(null);

  useEffect(() => {
    if (beneficiaryID && deliveryOptionID === 3) {
      (async function () {
        try {
          const res = await axios.get(
            `/api/Beneficiary/GetBeneficiaryMobileWalletDetails/${beneficiaryID}`
          );
          setBeneficiaryMobileDetails(res.data);
        } catch (error) {
          setBeneficiaryMobileDetails(null);
        }
      })();
    }
  }, [beneficiaryID, deliveryOptionID]);

  return { beneficiaryMobileDetails, setBeneficiaryMobileDetails };
}
