import React from "react";
import ExchangeRateCard from "../../Components/ExchangeRate/ExchangeRateCard";
import "./BankDeposit.css";
import { Container } from "react-bootstrap";
import useWindowDimensions from "../../../../hooks/useWindowDimensions";

const BankDeposit = () => {
  const { width } = useWindowDimensions();
  return (
    <div
      className="container-fluid px-0"
      style={{ display: "flex", flexDirection: "column" }}
    >
      <div className="bankdeposit-banner"></div>

      <Container
        className={`container justify-content-center ${
          width > 820 ? "w-75" : ""
        }`}
      >
        <div className="row pt-5">
          <div className="col-12 col-md-6 col-lg-6">
            <div className="row flex-direction-column text-start">
              <div className="col  w-75" id="swift-secureMoney">
                <h1>Swift Secure Money Transfers</h1>
              </div>

              <div className="col pt-3" id="swift-MobileMoney">
                <div className="">
                  <h1 className="mobile-money">Bank Deposit</h1>
                </div>
                <div className="" id="mobile-money-txt">
                  Your money is safe. You can send money to your recipient’s
                  Bank account around the world. A great way to send a large
                  amount of cash without having to pay high transfer fees.
                  <br />
                  <br />
                  Fast, easy, and convenient.
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-6 px-5 py-5">
            <ExchangeRateCard />
          </div>
        </div>
      </Container>
    </div>
  );
};

export default BankDeposit;
