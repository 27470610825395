/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from "react";
import { FormContext } from "../Registration";
import "./Stepper.css";

function Stepper() {
  const { activeStepIndex, setActiveStepIndex } = useContext(FormContext);

  useEffect(() => {
    const stepperItems = document.querySelectorAll(".stepper-item");

    stepperItems.forEach((step, i) => {
      if (i <= activeStepIndex) {
        step.classList.add("bg-active", "text-active");
      } else {
        step.classList.remove("bg-active", "text-active");
      }
    });
  }, [activeStepIndex]);

  const previousBtn = (key) => {
    if (activeStepIndex > key) {
      setActiveStepIndex(key);
    }
  };

  return (
    <div className="stepper">
      <div className="stepper-item" onClick={() => previousBtn(0)}>
        <div className="stepper-label">Personal Info</div>
      </div>

      <div className={`progress-bar ${activeStepIndex >= 1 ? "progress-bar-active" : ""}`}></div>
      <div className="stepper-item" onClick={() => previousBtn(1)}>
       <div className="stepper-label">Email</div>
      </div>

      <div className={`progress-bar ${activeStepIndex >= 2 ? "progress-bar-active" : ""}`}></div>
      <div className="stepper-item" onClick={() => previousBtn(2)}>
        <div className="stepper-label">Country</div>
      </div>

      <div className={`progress-bar ${activeStepIndex >= 3 ? "progress-bar-active" : ""}`}></div>
      <div className="stepper-item" onClick={() => previousBtn(3)}>
        <div className="stepper-label">2FA</div>
      </div>

      <div className={`progress-bar ${activeStepIndex >= 4 ? "progress-bar-active" : ""}`}></div>
      <div className="stepper-item" onClick={() => previousBtn(4)}>
        <div className="stepper-label">Password</div>
      </div>
    </div>
  );
}

export default Stepper;
