import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const KycSuccess = () => {
  const navigate = useNavigate();

  const id = window.location.href.split("=");
  const sessionId = id[1];
  const [isLoading, setIsLoading] = useState(true);
  const [successMsg, setSuccessMsg] = useState();
  const [kycStatus, setKycStatus] = useState(false);
  const [cta, setCta] = useState("Restart KYC");

  useEffect(() => {
    if (sessionId == null || sessionId === "") {
      setSuccessMsg("Session Id is not present");
      setIsLoading(false);
      setKycStatus(true); //force user to go to dashboard
      setCta("Go to Dashboard");
    } else {
      setSuccessMsg("Your eKYC is successfully completed");
      setCta("Go to dashboard");
      setKycStatus(true);
      setIsLoading(false);
    }
  }, [sessionId]);

  // OnSubmit
  const submitKycdetails = (values) => {
    if (kycStatus) {
      navigate("/dashboard");
    } else {
      setIsLoading(true);
      navigate("/kyc");
    }
  };

  const FullPageLoader = ({ msg }) => {
    return (
      <>
        <div
          className="kyc-message"
          style={{
            padding: "5rem 0 0 0",
          }}
        >
          {msg}
        </div>
        <div
          className="loader-container"
          style={{
            display: "flex",
            height: "80vh",
            width: "100%",
            alignItems: "center",
          }}
        >
          <div className="loader">
            <div className="loader-inner">
              <div className="loader-inner-circle"></div>
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <div>
      {isLoading ? (
        <>
          <FullPageLoader message={successMsg} />
        </>
      ) : (
        <>
          <div
            className="kyc-message"
            style={{
              padding: "5rem 0 0 0",
            }}
          >
            {successMsg}
          </div>
          <button
            style={{
              margin: "150px 0px 150px",
              backgroundColor: "rgb(231, 112, 22)",
              border: "none",
              color: "rgb(255, 255, 255)",
              padding: "13px 10px",
              borderRadius: "6px",
              width: "40%",
              transition: "all 0.9s ease 0s",
              textTransform: "uppercase",
              fontSize: "13px",
            }}
            type="submit"
            onClick={submitKycdetails}
          >
            {cta}
          </button>
        </>
      )}
    </div>
  );
};

export default KycSuccess;
