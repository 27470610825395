import { ErrorMessage, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";
import axios from "../api/axios/axios";
import paymentIcon from "../assets/images/icons/add-payment-icon.png";
import reload from "../assets/images/icons/reload.png";
import CustomButton from "../tools/CustomButton";
import CustomInput from "../tools/CustomInput";

const ContactForm = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [code, setCode] = useState("");
  const [number1, setNumber1] = useState(0);
  const [number2, setNumber2] = useState(0);
  const [opration, setOpration] = useState("+");

  const [initialValues] = useState({
    firstName: "",
    lastName: "",
    mobileNumber: "",
    message: "",
  });

  const ContactSchema = Yup.object().shape({
    firstName: Yup.string()
      .min(3, "Too Short!")
      .max(50, "Too Long!")
      .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed")
      .required("Required"),
    lastName: Yup.string()
      .min(3, "Too Short!")
      .max(50, "Too Long!")
      .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed")
      .required("Required"),
    mobileNumber: Yup.string()
      .min(9, "Exact 9 digits allowed for mobile number")
      .max(9, "Exact 9 digits allowed for mobile number")
      .matches(/^[0-9\s]+$/, "Only numbers are allowed")
      .required("Invalid mobile number"),
    message: Yup.string().required("Message is required"),
    challenge: Yup.number().required("Challenge is required"),
  });

  useEffect(() => {
    getCode();
    return () => {
      setCode(""); // unmount the state
    };
  }, []);

  const getCode = () => {
    setIsLoading(true);
    setCode("");
    axios
      .get(`/api/contact-us-pre`)
      .then((res) => {
        if (res.data) {
          setCode(res.data.ans);
          setNumber1(res.data.number1);
          setNumber2(res.data.number2);
          setOpration(res.data.opration);
        }
        setIsLoading(false);
      })
      .catch((e) => {
        toast.error(e.response.data.error);
        setIsLoading(false);
        setCode("");
      });
  };
  const contactSubmit = (values) => {
    setIsLoading(true);
    axios
      .post(
        `/api/Utility/SaveContactForm`,
        {
          firstName: values.firstName,
          lastName: values.lastName,
          mobileNumber: values.mobileNumber,
          message: values.message,
          challenge: values.challenge,
        },
        {
          headers: {
            challenge: code,
          },
        }
      )
      .then((res) => {
        setIsLoading(false);
        toast.success("Successfully contact us message saved");
        navigate("/contact");
      })
      .catch((err) => {
        toast.error(err.response.data.error);
        setIsLoading(false);
      });
  };

  return (
    <>
      <article className="home-wrapper">
        <section>
          <div
            className="money-process-list-content11 "
            style={{ maxWidth: "80%", margin: "0 auto", marginBottom: "50px" }}
          >
            <Formik
              initialValues={initialValues}
              validationSchema={ContactSchema}
              onSubmit={(val) => contactSubmit(val)}
              enableReinitialize={true}
            >
              {({
                handleChange,
                handleBlur,
                handleSubmit,
                errors,
                touched,
              }) => (
                <Form className="process-ol" onSubmit={handleSubmit}>
                  <div className="process-form">
                    <div className="payment-details-add">
                      <img src={paymentIcon} alt="payment icon" />
                      <h2>Contact Form</h2>
                    </div>

                    <div className="full-input money-half-input">
                      <label className="text-orange">
                        ( Enter your name as it appears on your ID )
                      </label>
                    </div>
                    <div className="half-input money-half-input">
                      <label>First Name</label>
                      <CustomInput
                        type="text"
                        name="firstName"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        style={{
                          border:
                            errors.firstName && touched.firstName
                              ? "1px solid red"
                              : "",
                        }}
                      />
                    </div>

                    <div className="half-input money-half-input">
                      <label>Last Name</label>
                      <CustomInput
                        type="text"
                        name="lastName"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        style={{
                          border:
                            errors.lastName && touched.lastName
                              ? "1px solid red"
                              : "",
                        }}
                      />
                    </div>
                    <div className="half-input money-half-input">
                      <label>Mobile Number</label>
                      <CustomInput
                        type="text"
                        name="mobileNumber"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        style={{
                          border:
                            errors.mobileNumber && touched.mobileNumber
                              ? "1px solid red"
                              : "",
                        }}
                      />
                    </div>
                    <div className="half-input money-half-input">
                      <label>Message</label>
                      <CustomInput
                        type="text"
                        name="message"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        style={{
                          border:
                            errors.message && touched.message
                              ? "1px solid red"
                              : "",
                        }}
                      />
                    </div>
                    <div style={{ clear: "both" }}></div>
                    <span style={{ float: "left", padding: "10px" }}>
                      {number1}
                    </span>
                    <span style={{ float: "left", padding: "10px" }}>
                      {opration}
                    </span>
                    <span style={{ float: "left", padding: "10px" }}>
                      {number2}
                    </span>
                    <span style={{ float: "left", padding: "10px" }}>=</span>
                    <input
                      type="number"
                      name="challenge"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      style={{
                        width: "3rem",
                        border:
                          errors.challenge && touched.challenge
                            ? "1px solid red"
                            : "",
                      }}
                    />

                    <span
                      style={{
                        float: "left",
                        marginLeft: "1rem",
                        paddingTop: "10px",
                      }}
                    >
                      <img
                        src={reload}
                        style={{ width: "20px", cursor: "pointer" }}
                        onClick={() => getCode()}
                        alt="Refresh code"
                        title="Refresh code"
                      />
                    </span>
                    <div
                      className="error-text"
                      style={{ display: "block", clear: "both" }}
                    >
                      <ErrorMessage name="challenge" />
                    </div>
                    <div
                      className="continue-process"
                      style={{ marginBottom: "50px" }}
                    >
                      <CustomButton
                        title="Submit"
                        type="submit"
                        disabled={isLoading}
                        className={
                          isLoading ? "load-button spin" : "load-button"
                        }
                      />
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </section>
      </article>
    </>
  );
};

export default ContactForm;
