import { ErrorMessage, Form, Formik } from "formik";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";
import * as Yup from "yup";
import axios from "../api/axios/axios";
import paymentIcon from "../assets/images/icons/add-payment-icon.png";
import Modal from "../components/Modal";
import useFetchCities from "../hooks/useFetchCities";
import useFetchCountries from "../hooks/useFetchCountries";
import useFetchStates from "../hooks/useFetchStates";
import { ProfileContext } from "../routes/PrivateRoute";
import CustomButton from "../tools/CustomButton";
import CustomInput from "../tools/CustomInput";
import { Nationalities } from "../utils/Nationalities";
import UploadAdditionalDocuments from "./UploadAdditionalDocuments";

const editMobileButtonsStyle = { background: "transparent", color: "orange" };

const Profile = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [startDate] = useState(null);
  const [color] = useState("#000");
  const [isEditing, setIsEditing] = useState(false);
  const [isVerified, setIsVerified] = useState(false);
  const [mobileNumber, setMobileNumber] = useState("");
  const [modal, setModal] = useState(false);
  const [countryCallingCode, setCountryCallingCode] = useState("+254");
  const Toggle = () => setModal(!modal);
  const [config, setConfig] = useState({ type: "", receiver: "" });

  const formikRef = React.useRef();

  const [uploadAddDoc, setUploadAddDoc] = useState(false);

  const [stateCode, setStateCode] = useState("");
  const [, setStateOption] = useState({});
  const [, setCityOption] = useState({});
  const [countryIdOption, setCountryIdOption] = useState(0);

  const [initialValues, setInitialValues] = useState({
    firstName: "",
    middleName: "",
    lastName: "",
    address: "",
    country: "",
    nationality: "",
    dob: "",
    occupation: "",
    mobileNo: "",
    email: "",
    addressLine: "",
    addressStreet: "",
    state: "",
    city: "",
  });

  const { states } = useFetchStates(countryIdOption);
  const { cities, setCities } = useFetchCities(countryIdOption, stateCode);
  const { selectedCountry } = useFetchCountries();

  const { kycStatus, cta, kycPath, kycMessage, kycCheck, kycDetails, kycLoader } = useContext(ProfileContext);
  useEffect(() => {
    if (kycDetails) {
      const {
        firstName,
        middleName,
        lastName,
        email,
        phoneNumber,
        city,
        state,
        address,
        countryID,
        occupation,
        nationality,
        dob,
      } = kycDetails;

      const countryCode = phoneNumber.includes("+") ? phoneNumber.substring(1, 4) : phoneNumber.substring(0, 3);
      const addressItems = address?.split(",");
      const fullAddress = addressItems?.length > 1;

      setIsLoading(false);
      setInitialValues((prevState) => ({
        ...prevState,
        firstName,
        middleName: middleName ?? "",
        lastName,
        mobileNo: phoneNumber.split(countryCode)[1],
        email,
        address: address ?? "",
        country: countryID ?? "",
        nationality: nationality ?? "",
        dob: moment(dob).format("DD-MM-YYYY"),
        occupation: occupation ?? "",
        addressLine: fullAddress ? addressItems[0] : "",
        addressStreet: fullAddress ? addressItems[1] : "",
        state: state ? state : "",
        city: city ? city : "",
      }));

      setCountryIdOption(countryID);
      setMobileNumber(phoneNumber.split(countryCode)[1]);
      setCountryCallingCode(`+${countryCode}`);
    }
  }, [kycDetails]);

  const handleState = (val, setFieldValue) => {
    setCities([]);
    setCityOption(null);
    setStateOption(val);
    setStateCode(val.Id);
    setFieldValue("state", val.Name);
  };

  const handleCity = (val, setFieldValue) => {
    setCityOption(val);
    setFieldValue("city", val.Name);
  };

  const editMobile = (setFieldTouched) => {
    setFieldTouched("mobileNo", false);
    setIsEditing(!isEditing);
    setIsVerified(false);
    setMobileNumber(initialValues.mobileNo);
  };

  const onChangeMobile = (value, setFieldValue) => {
    setFieldValue("mobileNo", value.target.value);
    setMobileNumber(value.target.value);
  };

  const verifyMobileNumer = () => {
    if (mobileNumber) {
      setModal(true);
      setConfig({
        type: "sms",
        receiver: `${countryCallingCode.replace("+", "")}${mobileNumber}`,
      });
    }
  };

  const onVerification = ({ option }) => {
    if (option === "sms") {
      setIsVerified(true);
      formikRef.current.setFieldError("mobileNo", "");
    }
    setModal(false);
  };

  const profileSchema = Yup.object().shape({
    mobileNo: Yup.string()
      .min(9, "Exact 9 digits allowed for mobile number")
      .max(9, "Exact 9 digits allowed for mobile number")
      .matches(/^[0-9\s]+$/, "Only numbers are allowed")
      .test("mobileNo", "Please verify Mobile number with OTP", (value) => {
        return isEditing === isVerified;
      })
      .required("Invalid phone number"),
    country: Yup.string().required("Country  is required"),
    nationality: Yup.string().required("Nationality is required"),
    occupation: Yup.string().required("Occupation is required"),
    addressLine: Yup.string().required("Address line is required"),
    addressStreet: Yup.string().required("Address street is required"),
    state: Yup.string().required("State is required"),
    city: Yup.string().required("City is required"),
  });

  const saveProfile = (values) => {
    setIsLoading(true);
    let updateUserDetails = {
      occupation: values.occupation,
      address: `${values.addressLine}, ${values.addressStreet.trim()}, ${values.city}, ${values.state}`,
      nationality: values.nationality,
      country: values.country,
      phoneNumber: "254" + mobileNumber,
      city: values.city,
      state: values.state,
    };

    axios
      .post(`/api/Utility/UpdatePrimaryInfo`, updateUserDetails)
      .then((res) => {
        setIsLoading(false);
        toast.success("User profile saved.");

        navigate("/profile-confirm");
      })
      .catch((err) => {
        toast.error("Error saving profile.");
        setIsLoading(false);
      });
  };

  const CustomStyle = {
    option: (provided, state) => ({
      ...provided,
      textAlign: "left",
    }),
    control: () => ({
      border: "1px solid red",
      display: "Flex",
      alignItems: "center",
    }),
  };

  const defaultStyle = {
    option: (provided, state) => ({
      ...provided,
      textAlign: "left",
    }),
  };

  const initialTouch = {
    addressLine: true,
    addressStreet: true,
    address: true,
    city: true,
    state: true,
  };

  const defaultState = (state) => {
    if (state)
      // eslint-disable-next-line array-callback-return
      return states.filter((singleState) => {
        if (singleState.Name === state) {
          return singleState;
        }
      })[0];
  };

  useEffect(() => {
    if (initialValues.state && states.length > 0)
      // eslint-disable-next-line array-callback-return
      return states.filter((singleState) => {
        if (singleState.Name === initialValues.state) setStateCode(singleState?.Id);
      });

    return () => {};
  }, [initialValues.state, states]);

  return (
    <>
      {modal ? <Modal show={modal} close={Toggle} success={onVerification} config={config} /> : null}
      {kycLoader ? (
        <div className="mainDiv">
          <div className="loading"></div>
        </div>
      ) : null}

      {kycCheck ? (
        <div
          style={{
            position: "fixed",
            padding: "0",
            margin: "0",
            top: "0",
            left: "0",
            width: "100%",
            height: "100%",
            background: "rgb(0, 0, 0,0.5)",
            zIndex: "9999",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              backgroundColor: "white",
              width: "40rem",
              padding: "2rem",
            }}
          >
            {!uploadAddDoc ? (
              <>
                <div className=" otp-container">
                  <h4 className="text-center">KYC</h4>
                  <p></p>
                  <div className="process-ol">
                    <div className="add-label-input full-input money-half-input">
                      <label style={{ textAlign: "center", fontSize: "15px" }}>{kycMessage}</label>
                    </div>
                  </div>
                </div>
                <div className="modal-footer w-100 d-flex justify-content-center">
                  <div className="display-flex money-half-input align-items-center">
                    <div className="padding-x steps-inputs-column1 close">
                      <button onClick={() => (kycPath === "/uploadAddDoc" ? setUploadAddDoc(true) : navigate(kycPath))}>
                        {cta}
                      </button>
                    </div>
                    <div
                      style={{
                        backgroundColor: "#c7d6fd",
                        borderRadius: "4px",
                        color: "#1b223a",
                        fontSize: "13px",
                        padding: "13px 40px",
                        cursor: "pointer",
                      }}
                      onClick={() => navigate("/dashboard")}
                    >
                      <button style={{ background: "none" }}>Back</button>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <UploadAdditionalDocuments />
            )}
          </div>
        </div>
      ) : null}

      {isLoading ? (
        <div className="mainDiv">
          <div className="loading"></div>
        </div>
      ) : (
        <div className="beneficiary-details-wrapper">
          <div className="send-money-tab2 tab-width-3">
            <div id="horizontalTab" className="money-process-lists2">
              <Formik
                validateOnMount
                initialValues={initialValues}
                validationSchema={profileSchema}
                onSubmit={(val) => saveProfile(val)}
                enableReinitialize
                innerRef={formikRef}
                initialTouched={initialTouch}
              >
                {({
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  setFieldValue,
                  touched,
                  setFieldTouched,
                  errors,
                  values,
                }) => (
                  <Form onSubmit={handleSubmit}>
                    <div className="process-form process-ol">
                      <div className="payment-details-add">
                        <img src={paymentIcon} alt="payment" />
                        <h5>USER PROFILE INFORMATION</h5>
                      </div>

                      <div className="full-input money-half-input">
                        <h4 style={{ textAlign: "center" }}>
                          KYC Status : <span style={{ color: `${color}` }}>{kycStatus}</span>
                        </h4>
                      </div>
                      <div className="third-input money-half-input">
                        <label>First Name</label>
                        <CustomInput
                          type="text"
                          name="firstName"
                          value={values.firstName}
                          onBlur={handleBlur}
                          disabled={true}
                          style={{
                            border: errors.firstName && touched.firstName ? "1px solid red" : "",
                          }}
                        />
                      </div>
                      <div className="third-input money-half-input">
                        <label>Middle Name</label>
                        <CustomInput
                          type="text"
                          name="middleName"
                          value={values.middleName}
                          disabled={true}
                          onBlur={handleBlur}
                          style={{
                            border: errors.middleName && touched.middleName ? "1px solid red" : "",
                          }}
                        />
                      </div>
                      <div className="third-input money-half-input">
                        <label>Last Name</label>
                        <CustomInput
                          type="text"
                          name="lastName"
                          value={values.lastName}
                          disabled={true}
                          onBlur={handleBlur}
                          style={{
                            border: errors.lastName && touched.lastName ? "1px solid red" : "",
                          }}
                        />
                      </div>
                      <div className="half-input money-half-input">
                        <label>Phone Number</label>
                        <div
                          style={{
                            clear: "both",
                            display: "flex",
                          }}
                        >
                          <input
                            type="text"
                            disabled={true}
                            defaultValue={countryCallingCode}
                            style={{
                              width: "3rem",
                              padding: "13px 0",
                              textAlign: "center",
                              marginRight: "0.4rem",
                            }}
                          />
                          <input
                            type="number"
                            name="mobileNo"
                            value={mobileNumber}
                            disabled={!isEditing}
                            onChange={(val) => onChangeMobile(val, setFieldValue)}
                            onBlur={handleBlur}
                            style={{
                              border: errors.mobileNo && touched.mobileNo ? "1px solid red" : "",
                            }}
                          />
                        </div>
                        <div className="error-text">
                          <ErrorMessage name="mobileNo" />
                        </div>
                        <div
                          style={{
                            clear: "both",
                            display: "flex",
                            justifyContent: "space-between",
                            width: "8rem",
                            paddingTop: "0.5rem",
                          }}
                        >
                          {isEditing && !isVerified && (
                            <button
                              style={{ ...editMobileButtonsStyle }}
                              type="button"
                              onClick={() => verifyMobileNumer()}
                            >
                              Verify
                            </button>
                          )}
                          {!isVerified && (
                            <button
                              style={{ ...editMobileButtonsStyle }}
                              type="button"
                              onClick={() => editMobile(setFieldTouched)}
                            >
                              {isEditing ? "Cancel" : "Edit"}
                            </button>
                          )}
                          {isVerified && (
                            <span
                              style={{
                                fontSize: "12px",
                                color: "green",
                              }}
                            >
                              Verified
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="half-input money-half-input">
                        <label>Email Address</label>
                        <CustomInput
                          type="email"
                          name="email"
                          value={values.email}
                          disabled={true}
                          onBlur={handleBlur}
                          style={{
                            border: errors.email && touched.email ? "1px solid red" : "",
                          }}
                        />
                      </div>
                      <div className="full-input"></div>

                      <div className="half-input money-half-input">
                        <label>Country </label>
                        <Select
                          name="country"
                          value={selectedCountry.filter((c) => Number(c.id) === Number(values.country))[0]}
                          isDisabled={true}
                          options={selectedCountry}
                          getOptionLabel={(option) => option.name}
                          getOptionValue={(option) => option.id}
                          placeholder=""
                          onBlur={handleBlur}
                          styles={errors.country && touched.country ? CustomStyle : defaultStyle}
                          noOptionsMessage={() => "Country is loading..."}
                        />
                        <div className="error-text" style={{ height: "0px" }}>
                          <ErrorMessage name="country" />
                        </div>
                      </div>
                      <div className="half-input money-half-input">
                        <label>Nationality </label>
                        <Select
                          name="nationality"
                          value={{ name: values.nationality }}
                          isDisabled={true}
                          options={Nationalities}
                          getOptionLabel={(option) => option.name}
                          getOptionValue={(option) => option.name}
                          placeholder=""
                          onBlur={handleBlur}
                          styles={errors.nationality && touched.nationality ? CustomStyle : defaultStyle}
                          noOptionsMessage={() => "Nationality is loading..."}
                        />
                        <div className="error-text" style={{ height: "0px" }}>
                          <ErrorMessage name="nationality" />
                        </div>
                      </div>
                      <div className="half-input money-half-input">
                        <label>County </label>
                        <Select
                          name="state"
                          value={defaultState(values.state)}
                          onChange={(val) => handleState(val, setFieldValue)}
                          options={states}
                          getOptionLabel={(option) => option.Name}
                          getOptionValue={(option) => option.Name}
                          placeholder="Select State"
                          onBlur={handleBlur}
                          styles={errors.state && touched.state ? CustomStyle : defaultStyle}
                          noOptionsMessage={() => "State is loading..."}
                        />
                        <div className="error-text" style={{ height: "0px" }}>
                          <ErrorMessage name="state" />
                        </div>
                      </div>
                      <div className="half-input money-half-input">
                        <label>City </label>
                        <Select
                          name="city"
                          value={cities.filter((singleCity) => singleCity.Name === values.city)[0]}
                          onChange={(val) => handleCity(val, setFieldValue)}
                          options={cities}
                          getOptionLabel={(option) => option.Name}
                          getOptionValue={(option) => option.Name}
                          placeholder="Select City"
                          onBlur={handleBlur}
                          styles={errors.city && touched.city ? CustomStyle : defaultStyle}
                          noOptionsMessage={() => "City is loading..."}
                        />
                        <div className="error-text" style={{ height: "0px" }}>
                          <ErrorMessage name="city" />
                        </div>
                      </div>

                      <div className="half-input money-half-input">
                        <label>Address Line</label>
                        <CustomInput
                          type="text"
                          name="addressLine"
                          placeholder="Building/landmark e.g Morningside park office"
                          value={values.addressLine}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          style={{
                            border: errors.addressLine && touched.addressLine ? "1px solid red" : "",
                          }}
                        />
                      </div>
                      <div className="half-input money-half-input">
                        <label>Address Street</label>
                        <CustomInput
                          type="text"
                          name="addressStreet"
                          placeholder="Street/road e.g Ngong RD"
                          value={values.addressStreet}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          style={{
                            border: errors.addressStreet && touched.addressStreet ? "1px solid red" : "",
                          }}
                        />
                      </div>

                      <div className="half-input money-half-input">
                        <label>Date of Birth</label>
                        <div className={errors.dob && touched.dob ? "date-error" : ""}>
                          <DatePicker
                            name="dob"
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            value={values.dob}
                            selected={startDate}
                            disabled={true}
                            onBlur={handleBlur}
                            placeholderText="Date of Birth"
                            dateFormat="dd-MM-yyyy"
                          />
                        </div>
                        <div className="error-text" style={{ height: "0px" }}>
                          <ErrorMessage name="dob" />
                        </div>
                      </div>
                      <div className="half-input money-half-input">
                        <label>Occupation</label>
                        <CustomInput
                          type="text"
                          name="occupation"
                          value={values.occupation}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          style={{
                            border: errors.occupation && touched.occupation ? "1px solid red" : "",
                          }}
                        />
                      </div>
                      <div className="continue-process">
                        <CustomButton title="Continue" type="Save" className={"load-button"} />
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Profile;
