import React from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import "video-react/dist/video-react.css";
import "./App.css";
import BaseRouter from "./routes/BaseRouter";
import "./assets/css/easy-responsive-tabs.css";
import "./assets/css/font-awesome.css";
import "./assets/css/owl.carousel.css";
import "./assets/css/owl.theme.css";
import "./assets/css/style.css";
import { CountryProvider } from "./context/route-context";
import { CloseButton } from "./components/CloseButton";

function App() {
  
  return (
    <CountryProvider>
      <div className="App">
        <ToastContainer closeButton={CloseButton} />
        <BaseRouter />
      </div>
    </CountryProvider>
  );
}

export default App;
