import { ErrorMessage, Form, Formik } from "formik";
import React from "react";
import Select from "react-select";

import { CustomStyle, defaultStyle } from "../../../../../utils/styles";
import { cashDetailsSchema } from "./formValidation";
import FloatingLabel from "../../../../../tools/FloatingLabel";

function CashDetails({ cashProps }) {
  const {
    formData: {
      deliveryType: {
        cashDetails: { cashPickupLocation, mobileNumber, mobileNumberCode },
      },
    },
    handleCashPickupLocation,
    cashPickupLocations,
    onSubmit,
  } = cashProps;

  return (
    <Formik
      initialValues={{ mobileNumberCode, mobileNumber, cashPickupLocation }}
      validationSchema={cashDetailsSchema}
      onSubmit={(val) => onSubmit(val)}
    >
      {({
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
        errors,
        touched,
        values,
      }) => (
        <Form
          id="registration-form"
          className="w-100 row justify-content-center "
          onSubmit={handleSubmit}
        >
          <div className="h3 text-center mb-2 step-title">
            Cash Pickup Details
          </div>
          <div className="form-group col-12 col-sm-8 col-lg-6 col-xl-4 row">
            <FloatingLabel label="Mobile Number" value={values.mobileNumber} />
            <div className="col-3 p-0" id="code-input-section">
              <input
                className="form-control form-input"
                type="text"
                disabled
                name="mobileNumberCode"
                value={values.mobileNumberCode}
              />
            </div>
            <div className="col-9 p-0">
              <input
                className={`form-control form-input mobile-input ${
                  errors.mobileNumber && touched.mobileNumber
                    ? "form-error"
                    : ""
                }`}
                value={values.mobileNumber}
                type="number"
                name="mobileNumber"
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder="7XXXXXXXX"
              />
            </div>
          </div>

          <div className="error-text col-12 text-center my-2">
            <ErrorMessage name="mobileNumber" />
          </div>

          <div className="col-12 col-sm-8 col-lg-6 col-xl-4">
            <FloatingLabel
              label="Pickup Location"
              value={values.cashPickupLocation}
            />
            {cashPickupLocations.length > 0 ? (
              <Select
                name="cashPickupLocation"
                value={
                  values.cashPickupLocation && {
                    name: values.cashPickupLocation,
                  }
                }
                onChange={(val) => handleCashPickupLocation(val, setFieldValue)}
                options={cashPickupLocations}
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option}
                placeholder="Select pickup location"
                onBlur={handleBlur}
                styles={
                  errors.cashPickupLocation && touched.cashPickupLocation
                    ? CustomStyle
                    : defaultStyle
                }
                noOptionsMessage={() => "Select country first"}
              />
            ) : (
              <input
                id="form-input"
                value={values.cashPickupLocation}
                type="text"
                name="cashPickupLocation"
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder="Pickup location"
                className={`form-control form-input ${
                  errors.cashPickupLocation && touched.cashPickupLocation
                    ? "form-error"
                    : ""
                }`}
              />
            )}
          </div>
          <div className="error-text col-12 text-center my-2">
            <ErrorMessage name="cashPickupLocation" />
          </div>
          <div className="col-12 col-sm-8 col-lg-6 col-xl-4">
            <button className="btn btn-orange w-100" type="submit">
              Next
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default CashDetails;
