import { useEffect, useState } from "react";
import axios from "../api/axios/axios";

export default function useFetchOperatingRegions() {
  const [operatingRegions, setOperatingRegions] = useState([]);

  useEffect(() => {
    axios
      .get(`/api/user/operatingregions`)
      .then((res) => {
        setOperatingRegions(res.data);
      })
      .catch((err) => {
        setOperatingRegions([]);
      });
  }, []);

  return { operatingRegions };
}
