import React, { createContext } from "react";
import { Navigate } from "react-router-dom";
import Footer from "../components/common/Footer";
import PrivateHeader from "../components/common/PrivateHeader";
import { useFetchAllUserDetails } from "../hooks/useFetchUserProfile";
import { getSessionData } from "../utils/session";

export const ProfileContext = createContext();
const PrivateRoute = (props) => {
  const isLoggedIn = getSessionData("token");
  const { kycDetails, cta, kycPath, kycMessage, kycCheck, kycLoader, kycStatus } = useFetchAllUserDetails();

  return (
    <>
      {isLoggedIn ? (
        <>
          <ProfileContext.Provider value={{ kycDetails, cta, kycPath, kycMessage, kycCheck, kycLoader, kycStatus }}>
            <PrivateHeader children={props.element} heading={props.heading} />
          </ProfileContext.Provider>
        </>
      ) : (
        <>
          <Navigate to="/login" />
        </>
      )}
      <Footer />
    </>
  );
};

export default PrivateRoute;
