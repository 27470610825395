import React from "react";
import ConfirmationPage from "./ConfirmationPage";

export default function ResetPasswordConfirmation() {
  return (
    <ConfirmationPage
      message="Password Changed Successfully"
      cta="login"
      ctalink="/login"
      Desc="your password has been updated successfully."
    />
  );
}
