import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "../api/axios/axios";
import { downloadReceipt } from "../api/downloadReceipt";
import { redirectPaymentOption } from "../api/redirectPaymentOption";
import { repeatTransaction } from "../api/repeatTransaction";
import { sendCopyOnEmail } from "../api/sendCopyOnEmail";
import DashboardModal from "../components/DashboardModal";
import { ProfileContext } from "../routes/PrivateRoute";
import PaginationComponent from "./Pagination/Pagination";

export default function TransactionHistory() {
  const navigate = useNavigate();
  const [, setInitData] = useState([]);
  const [userTransactions, setUserTransactions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [message, setMessage] = useState("No beneficiaries added yet");
  const [currentPage, setCurrentPage] = useState(1);

  const [modal, setModal] = useState(false);
  const [, setRefId] = useState();
  const [status, setStatus] = useState();
  const [holdReason, setHoldReason] = useState();
  const [failedReason, setFailedReason] = useState();
  const [id, setId] = useState();

  const { kycDetails } = useContext(ProfileContext);

 

  const openModal = (obj) => {
    setModal(true);
    setRefId(obj.id);
    setStatus(obj.transactionstatus);
    setHoldReason(obj.holdreason);
    setFailedReason(obj.cancelreason);
  };

  const closeModal = () => {
    setModal(false);
  };

  useEffect(() => {
    let isMounted = true;

    const getUserTransactionsList = async () => {
      setMessage("Loading...");

      let reqBody = {
        page: 0,
        size: 0,
        searchByReference: true,
        referenceNumber: "",
        senderFirstName: "",
        senderMiddleName: "",
        senderLastName: "",
        receiverFirstName: "",
        receiverMiddleName: "",
        receiverLastName: "",
        senderGender: "",
        receiverGender: "",
        receiverCountry: 0,
        senderCountry: 0,
        serviceType: 0,
        serviceProvider: 0,
        payerID: 0,
        fromDate: "",
        toDate: "",
        paidOutBy: "",
        paidOutFromDate: "",
        paidOutToDate: "",
        compliance: true,
        preApproved: true,
        senderPaymentReceived: true,
        origin: "",
        senderPayment: "",
        status: "",
      };
      try {
        const res = await axios.post(`/api/Transaction/GetUserTransactions`, reqBody);
        if (isMounted) {
          if (res.data) {
            setInitData(res.data);
            setUserTransactions(res.data);
          }
          setMessage("No transaction added yet");
          setIsLoading(false);
        }
      } catch (error) {
        if (isMounted) {
          setIsLoading(false);
          setUserTransactions([]);
          setMessage("Unable to get user transactions");
        }
      }
    };

    getUserTransactionsList();

    return () => (isMounted = false);
  }, []);

  // useMemo(() => {
  //   const firstPageIndex = (currentPage - 1) * pageSize;
  //   const lastPageIndex = firstPageIndex + pageSize;
  //   const beneData = userTransactions.slice(firstPageIndex, lastPageIndex);
  //   setCurrentPageData(beneData);
  // }, [currentPage, userTransactions]);

  const openTransactionDetails = (obj) => {
    navigate("/transactions-details", { state: obj });
  };

  // =========================================================================================
  // const [currentPage, setCurrentPage] = useState(1);
  // const [message, setMessage] = useState("No beneficiaries added yet");
  let itemsPerPage = 3;
  // Calculate the total number of pages
  const totalPages = Math.ceil(userTransactions.length / itemsPerPage);

  // Calculate the start and end index for the current page
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  // Slice the items array to get the items for the current page
  const currentItems = userTransactions.slice(startIndex, endIndex);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handlePrevClick = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextClick = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };
  // =========================================================================================

  const getAction = (items) => {
    if (items.transactionstatus === "Pending") {
      if (checkDateDifference(items)) {
        return (
          <div className="display-caption steps-inputs-wrapper">
            <span className="steps-inputs-column3">
              <button
                className={isLoading ? "load-button spin w-100" : "load-button w-100"}
                disabled={isLoading}
                onClick={() =>
                  redirectPaymentOption({
                    obj: items,
                    setIsLoading,
                    setId,
                    primaryInfo: kycDetails,
                    navigate,
                  })
                }
              >
                Complete Payment
                {id === items.id && (
                  <>
                    <span className="spinner"></span>
                  </>
                )}
              </button>
            </span>
          </div>
        );
      } else {
        return (
          <div className="display-caption steps-inputs-wrapper">
            <span>
              <button
                style={{
                  backgroundColor: "rgb(199, 214, 253)",
                  color: "grey",
                  borderRadius: " 4px",
                  padding: "13px 40px",
                  fontSize: " 13px",
                }}
                className="w-100"
                disabled
              >
                Failed
              </button>
            </span>
          </div>
        );
      }
    } else if (items.transactionstatus === "Paid Out") {
      return (
        <div className="display-caption steps-inputs-wrapper">
          <span className="steps-inputs-column3">
            <button
              style={{ margin: "5px", width: "140px" }}
              disabled={isLoading}
              className={isLoading ? "load-button spin w-50" : "load-button w-50"}
              onClick={() => repeatTransaction({ obj: items, setIsLoading, setId, navigate })}
            >
              Repeat
              {id === items.id && (
                <>
                  <span className="spinner"></span>
                </>
              )}
            </button>
            <button
              style={{ margin: "5px", width: "140px" }}
              disabled={isLoading}
              className={isLoading ? "load-button spin w-50" : "load-button w-50"}
              onClick={() =>
                downloadReceipt({
                  obj: items,
                  setIsLoading,
                  setId,
                  // setReceiptPdf,
                })
              }
            >
              <i className="fa fa-download" aria-hidden="true"></i>Pdf
              {id === items.id && (
                <>
                  <span className="spinner"></span>
                </>
              )}
            </button>
            <button
              style={{ margin: "5px", width: "140px" }}
              disabled={isLoading}
              className={isLoading ? "load-button spin w-50" : "load-button w-50"}
              onClick={() => sendCopyOnEmail({ txnId: items.id, setIsLoading })}
            >
              <i className="fa fa-envelope" aria-hidden="true"></i>Mail
              {id === items.id && (
                <>
                  <span className="spinner"></span>
                </>
              )}
            </button>
          </span>
        </div>
      );
    } else if (items.transactionstatus === "Locked") {
      return (
        <div className="display-caption steps-inputs-wrapper">
          <span className="steps-inputs-column3">
            <button className="w-100" onClick={() => openModal(items)}>
              Check Reason
            </button>
          </span>
        </div>
      );
    } else {
      return (
        <div className="display-caption steps-inputs-wrapper">
          <span className="steps-inputs-column3">
            <button className="w-100" onClick={() => openModal(items)}>
              Check status
            </button>
          </span>
        </div>
      );
    }
  };

  const checkDateDifference = (txn) => {
    var now = moment(new Date());
    var start = moment(txn.transaction_date, "DD/MM/YYYY");
    var duration = moment.duration(now.diff(start));
    var days = duration.asDays();
    return days < 3;
  };

  return (
    <>
      {modal ? (
        <DashboardModal
          show={modal}
          close={closeModal}
          // handleMobileNumber={handleMobileNumber}
          // onSubmit={makeOnlinePayment(
          //   refId,
          //   mobileNumber,
          //   setModal,
          //   setIsLoading
          // )}
          status={status}
          holdReason={holdReason}
          failedReason={failedReason}
        />
      ) : null}
      <div className="div-60 money-half-input order-first">
        <div className="transaction-table" style={{ width: "97%" }}>
          <table>
            <thead>
              <tr>
                <th>Service Name</th>
                <th>Ref No.</th>
                <th>Amount</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {currentItems.length < 1 ? (
                <tr>
                  <td colSpan="4" style={{ textAlign: "center" }}>
                    {message}
                  </td>
                </tr>
              ) : (
                <>
                  {currentItems.map((items, index) => {
                    return (
                      <tr key={index}>
                        <td>{items.transaction_type}</td>
                        <td onClick={() => openTransactionDetails(items)}>{items.transaction_ref}</td>
                        <td>{items.send_amount}</td>
                        <td>
                          <span>{items.transactionstatus}</span>
                        </td>
                        <td>{getAction(items)}</td>
                      </tr>
                    );
                  })}
                </>
              )}
            </tbody>
          </table>
        </div>
        <div className="pagination-app">
          <PaginationComponent
            totalPages={totalPages}
            currentPage={currentPage}
            handlePageChange={handlePageChange}
            handleNextClick={handleNextClick}
            handlePrevClick={handlePrevClick}
          />
          {/* <Pagination
            currentPage={currentPage}
            totalCount={userTransactions.length}
            pageSize={pageSize}
            onPageChange={(page) => setCurrentPage(page)}
          /> */}
        </div>
      </div>
    </>
  );
}
