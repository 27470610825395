import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Container,
  Nav,
  NavDropdown,
  Navbar,
  Offcanvas,
} from "react-bootstrap";
import { Link, NavLink, useNavigate } from "react-router-dom";

import Kenya from "../../../../assets/images/countries-flag/KE.png";
import Uganda from "../../../../assets/images/countries-flag/UG.png";
import user from "../../../../assets/images/icons/user.svg";
import Logo from "../../../../assets/images/upesi-logo.png";
import LogoutModal from "../../../../components/LogoutModal";
import { useCountry } from "../../../../context/route-context";
import useWindowDimensions from "../../../../hooks/useWindowDimensions";
import { getSessionData } from "../../../../utils/session";
import styles from "./Header.module.css";

export let KEFlag = (
  <span>
    <img className={styles.currentFlag} src={Kenya} alt="KEFlag" /> KE{" "}
  </span>
);
export let UGFlag = (
  <span>
    <img className={styles.currentFlag} src={Uganda} alt="UGFlag" /> UG{" "}
  </span>
);

const Header = () => {
  const navigate = useNavigate();

  const { country, setCountry } = useCountry();
  const updateCountry = useCallback(() => setCountry("ke"), [setCountry]);

  useEffect(() => {
    if (!country) {
      updateCountry();
    }
  }, [updateCountry, country]);

  const [currentCountry, setCurrentCountry] = useState({});
  const [navbar, setNavbar] = useState(false);
  const [modal, setModal] = useState(false);

  const { width } = useWindowDimensions();

  const mobileNav = useRef();

  const closeOffCanvas = () => mobileNav.current.backdrop?.click();

  useEffect(() => {
    if (country === "ke") {
      setCurrentCountry({
        flag: KEFlag,
        name: "KE",
        headerBgColor: styles.transparent,
      });
    } else {
      setCurrentCountry({
        flag: UGFlag,
        name: "UG",
        headerBgColor: styles.transparent,
      });
    }
  }, [country]);

  const navlinkClass = ({ isActive }) =>
    `${styles.navLink} ${isActive ? `${styles.active}` : ``}`;

  const changeBackground = () => {
    if (window.scrollY >= 66) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };

  const openModal = () => {
    setModal(true);
  };

  const closeModal = () => {
    setModal(false);
  };

  useEffect(() => {
    changeBackground();
    // adding the event when scroll change background
    window.addEventListener("scroll", changeBackground);

    return () => {
      window.removeEventListener("scroll", changeBackground);
    };
  });

  const countryNavs = [
    {
      country: "Kenya",
      name: "KE",
      flag: "KE.png",
      to: "",
    },
    {
      country: "Uganda",
      name: "UG",
      flag: "UG.png",
      to: "ug",
    },
  ];

  const handleCountryChange = (countryName) => {
    if (countryName) {
      setCountry(countryName);
    } else {
      setCountry("ke");
    }
    closeOffCanvas();
  };

  // eslint-disable-next-line array-callback-return
  const navs = countryNavs.map((nav) => {
    if (currentCountry.name !== nav.name) {
      // TODO: the use of this
      return (
        <NavLink
          to={nav.to}
          key={nav.country}
          onClick={() => handleCountryChange(nav.to)}
          className="dropdown-item"
        >
          <img
            className={`${styles.currentFlag} `}
            // src={require(`../../../../assets/images/countries-flag/${nav.flag}`)}
            src={require(`../../../../assets/images/countries-flag/${nav.flag}`)}
            alt={`${nav.country} flag`}
          />
          <span className="p-1">{nav.country}</span>
        </NavLink>
      );
    }
  });
  const isLoggedIn = getSessionData("token");
  const handleLogout = () => {
    localStorage.clear();
    setModal(false);
    navigate(`/login?country=${country}`);
  };

  const accountButtons = (
    <div sm={3} className={styles.accountBtn}>
      {isLoggedIn ? (
        <Link className={styles.logoutIcom} to="" onClick={openModal}>
          <img src={user} alt="user logo" />
          <span>Logout</span>
        </Link>
      ) : (
        <>
          <Link
            className={styles.loginBtn}
            onClick={closeOffCanvas}
            to={`/login?country=${country}`}
          >
            Login
          </Link>
          <Link
            className={styles.registerBtn}
            onClick={closeOffCanvas}
            to="/register"
          >
            Register
          </Link>
        </>
      )}
    </div>
  );

  return (
    <>
      {modal ? (
        <LogoutModal
          show={modal}
          close={closeModal}
          handleLogout={handleLogout}
        />
      ) : null}
      <Navbar
        sticky="top"
        expand="md"
        className={`${styles.header} ${currentCountry.headerBgColor} ${
          navbar ? `${styles.fixed_top}` : ""
        }`}
      >
        <Container className={`${width > 1200 ? "w-75" : ""}`}>
          <NavLink
            to={country === "ke" ? "/" : `/${country}`}
            id="main-logo"
            className={styles.logo}
          >
            <img src={Logo} alt="" />
          </NavLink>

          {width < 768 && <>{accountButtons}</>}
          <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-md`} />
          <Navbar.Offcanvas
            ref={mobileNav}
            id={`offcanvasNavbar-expand-md`}
            aria-labelledby={`offcanvasNavbarLabel-expand-md`}
            placement="end"
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title id={`offcanvasNavbarLabel-expand-md`}>
                <NavLink
                  to={country === "ke" ? "/" : `/${country}`}
                  onClick={closeOffCanvas}
                  className={styles.logo}
                >
                  <img src={Logo} alt="" />
                </NavLink>
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Nav className="justify-content-around  flex-grow-1 pe-3">
                <NavDropdown
                  className={`${styles.dropdown} ${styles.dropdown_flag}`}
                  title={currentCountry.flag}
                  id="nav-dropdown"
                >
                  {navs}
                </NavDropdown>
                {isLoggedIn && (
                  <NavLink
                    to="dashboard"
                    onClick={closeOffCanvas}
                    className={navlinkClass}
                  >
                    Dashboard
                  </NavLink>
                )}
                <NavDropdown
                  className={`${styles.dropdown}`}
                  title="Products"
                  id={`offcanvasNavbarDropdown-expand-md`}
                >
                  <NavLink
                    to="mobile-money"
                    onClick={closeOffCanvas}
                    className="dropdown-item"
                  >
                    Mobile Money
                  </NavLink>
                  <NavDropdown.Divider />
                  <NavLink
                    to="bank-deposit"
                    onClick={closeOffCanvas}
                    className="dropdown-item"
                  >
                    Bank Deposit
                  </NavLink>
                  <NavDropdown.Divider />
                  <NavLink
                    to="cash-pickup"
                    onClick={closeOffCanvas}
                    className="dropdown-item"
                  >
                    Cash Pickup
                  </NavLink>
                </NavDropdown>
                <NavDropdown
                  className={`${styles.dropdown}`}
                  title="Company"
                  id={`offcanvasNavbarDropdown-expand-md`}
                >
                  <NavLink
                    to="about-upesi"
                    onClick={closeOffCanvas}
                    className={`dropdown-item ${styles.company}`}
                  >
                    About Us
                  </NavLink>
                  <NavDropdown.Divider />
                </NavDropdown>
                <NavLink
                  to="support"
                  onClick={closeOffCanvas}
                  className={navlinkClass}
                >
                  Support
                </NavLink>
              </Nav>
              {width > 768 && <>{accountButtons}</>}
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Container>
      </Navbar>
    </>
  );
};

export default Header;
