import "./CustomerJourney.css";

function CustomerJourney() {
  return (
    <div
      style={{
        position: "relative",
        paddingTop: "56.25%",
        alignSelf: "center",
      }}
    >
      <iframe
        title="upesi"
        id="ytplayer"
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "90%",
        }}
        src="https://www.youtube.com/embed/LeARDqBpWmA?autoplay=1&mute=1&enablejsapi=0&loop=1"
      ></iframe>
    </div>
  );
}

export default CustomerJourney;
