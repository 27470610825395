import { useEffect, useState } from "react";
import axios from "../api/axios/axios";

export default function useFetchSourceofFunds({
  customerType,
  senderService,
  toCountryID,
  receiverService,
  serviceTypeID,
}) {
  const [fundsList, setFundsList] = useState([]);
  useEffect(() => {
    if (toCountryID && serviceTypeID && receiverService && senderService) {
      let sourceOfFund = {
        customerType,
        toCountryID,
        paymentService: `${senderService}2${receiverService}`,
        serviceTypeID,
        all: true,
        SystemCategoryContext: "Source of Funds",
      };

      axios
        .post(`/api/Transaction/GetSourceofFunds`, sourceOfFund)
        .then((res) => {
          setFundsList(res.data.items);
        })
        .catch(() => {
          setFundsList([]);
        });
    }
  }, [
    customerType,
    senderService,
    toCountryID,
    receiverService,
    serviceTypeID,
  ]);

  return { fundsList, setFundsList };
}
