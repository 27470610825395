import { toast } from "react-toastify";
import axios from "./axios/axios";

export const sendCopyOnEmail = ({ txnId, setIsLoading }) => {
  setIsLoading(true);
  axios
    .get(`/api/Transaction/SendTransactionReceipt/${txnId}`)
    .then((res) => {
      setIsLoading(false);
      toast.success("Receipt sent on registered email address");
    })
    .catch((err) => {
      toast.error("Error in sending receipt on registered email address");
      setIsLoading(false);
    });
};
