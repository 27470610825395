import CryptoJS from "crypto-js";

export default function Encrypt(text) {
  const key = "#i2dsNALrV9^XXEtE8Zt%h@fZGHBYJLX";
  var keyBytes = CryptoJS.enc.Hex.parse(CryptoJS.SHA256(key).toString());
  var encrypted = CryptoJS.AES.encrypt(text, keyBytes, {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7,
  });

  var encryptedpassword = encrypted.ciphertext.toString(CryptoJS.enc.Hex);
  return encryptedpassword;
}
