import { ErrorMessage, Form, Formik } from "formik";
import React from "react";

import { mobileDetailsSchema } from "./formValidation";
import FloatingLabel from "../../../../../tools/FloatingLabel";

function MobileDetails({ props }) {
  const { formData, onSubmit } = props;
  const { countryName, deliveryOption } = formData.deliveryMethod;
  return (
    <Formik
      initialValues={{ ...formData.deliveryType.mobileDetails }}
      validationSchema={mobileDetailsSchema(countryName, deliveryOption)}
      onSubmit={(val) => onSubmit(val)}
    >
      {({ handleChange, handleBlur, handleSubmit, errors, touched, values }) => (
        <Form id="registration-form" className="w-100 row justify-content-center" onSubmit={handleSubmit}>
          <div className="h3 text-center mb-2 step-title">Mobile Details</div>
          <div className="form-group col-12 col-sm-8 col-lg-6 col-xl-4 row">
            <FloatingLabel label="Mobile Number" value={values.walletMobileNumber} />
            <div className="col-3 p-0" id="code-input-section">
              <input
                className="form-control form-input"
                type="text"
                disabled
                name="walletMobileCode"
                value={values.walletMobileCode}
              />
            </div>
            <div className="col-9 p-0">
              <input
                className={`form-control form-input mobile-input  ${
                  errors.walletMobileNumber && touched.walletMobileNumber ? "form-error" : ""
                }`}
                value={values.walletMobileNumber}
                type="number"
                name="walletMobileNumber"
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder="7XXXXXXXX"
              />
            </div>
          </div>
          <div className="error-text  col-12 text-center my-2">
            <ErrorMessage name="walletMobileNumber" />
          </div>

          {countryName === "UGANDA" && (
            <>
              <div className="form-group col-12 col-sm-8 col-lg-6 col-xl-4" id="checkbox-wrapper">
                <input
                  type="checkbox"
                  name="hasMobileMoneyProvider"
                  checked={values.hasMobileMoneyProvider}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className="form-check-input checkbox"
                  // style={{
                  //   border: errors.hasMobileMoneyProvider && touched.hasMobileMoneyProvider ? "1px solid red" : "",
                  // }}
                />
                <label className="form-check-label fs-6  mb-0" id="checkbox-label" htmlFor="hasMobileMoneyProvider">
                  Use FlexiWallet for this Account
                </label>
              </div>
              <div className="error-text  col-12 text-center my-2">
                {/* <ErrorMessage name="mobileMoneyProviderID" /> */}
              </div>
            </>
          )}

          {/* TODO: this condition to be removed and fetch fields from backend */}
          {countryName === "CHINA" && (
            <>
              <div className="form-group col-12 col-sm-8 col-lg-6 col-xl-4">
                <FloatingLabel label="Wallet ID" value={values.walletId} />
                <input
                  value={values.walletId}
                  type="text"
                  name="walletId"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={`form-control form-input ${errors.walletId && touched.walletId ? "form-error" : ""}`}
                  placeholder="Wallet ID"
                />
              </div>
              <div className="error-text col-12 text-center my-2">
                <ErrorMessage name="walletId" />
              </div>
            </>
          )}

          <div className="col-12 col-sm-8 col-lg-6 col-xl-4">
            <button className="btn btn-orange w-100" type="submit">
              Next
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default MobileDetails;
