import React, { useCallback, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Select from "react-select";
import { deleteBeneficiary } from "../api/deleteBeneficiary";
import PaginationComponent from "../components/Pagination/Pagination";
import { getBeneficiaryServiceType } from "../hooks/useFetchAllBeneficiaryDetailsById";
import useFetchBeneficiaries from "../hooks/useFetchBeneficiaries";
import useFetchCountries from "../hooks/useFetchCountries";
import CustomModal from "../tools/CustomModal";
import { defaultStyle } from "../utils/styles";

const FILTER_OPTIONS = [
  { option: 1, filterOption: "Name" },
  { option: 2, filterOption: "Country" },
];

export default function ManageBeneficiaries() {
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);

  const [modal, setModal] = useState(false);
  const [beneficiaryId, setBeneficiaryId] = useState();
  const [name, setName] = useState("");
  const [country, setCountry] = useState(null);

  const [filterOption, setFilterOption] = useState(null);
  const [onFocus, setOnFocus] = useState(false);
  const { selectedCountry } = useFetchCountries();

  const { isLoading, message, initData, beneficiaries, setBeneficiaries, setIsLoading, setBeneficiaryDeleted } =
    useFetchBeneficiaries();

  const benList = useCallback(
    (val) => {
      let beneList = [];
      if (filterOption?.option === 2) {
        setName("");
        beneList = initData.filter((bene) => {
          // return bene.countryID === val.id;
          return bene.countryName.trim() === val.name.trim();
        });
      } else {
        setCountry(null);
        beneList = initData.filter((bene) => {
          return (
            bene.firstName?.toLowerCase().includes(val) ||
            bene.middleName?.toLowerCase().includes(val) ||
            bene.lastName?.toLowerCase().includes(val) ||
            bene.nickName?.toLowerCase().includes(val)
          );
        });
      }

      return beneList;
    },
    [filterOption?.option, initData]
  );

  useEffect(() => {
    if ((!onFocus && name) || country) {
      let filterParam = country ? country : name.toLowerCase();
      setCurrentPage(1);
      setBeneficiaries(benList(filterParam));
    } else {
      setBeneficiaries(initData);
    }
  }, [benList, name, onFocus, country, initData]);

  const openModal = (obj) => {
    setModal(true);
    setBeneficiaryId(obj?.beneficiaryID);
  };

  const closeModal = () => {
    setModal(false);
  };

  const editBeneficiary = async (obj) => {
    await getBeneficiaryServiceType(obj, navigate, "EDIT_BENEFICIARY");
  };

  const sendMoney = async (obj) => {
    await getBeneficiaryServiceType(obj, navigate);
  };

  let itemsPerPage = 3;
  // Calculate the total number of pages
  const totalPages = Math.ceil(beneficiaries.length / itemsPerPage);

  // Calculate the start and end index for the current page
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  // Slice the items array to get the items for the current page
  const currentItems = beneficiaries.slice(startIndex, endIndex);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handlePrevClick = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextClick = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const tableBody =
    currentItems.length < 1 ? (
      <tr>
        <td colSpan="4" style={{ textAlign: "center" }}>
          {message}
        </td>
      </tr>
    ) : (
      currentItems.map(function (item, index) {
        return (
          <tr key={index}>
            <td>{item.name}</td>
            <td>{item.nickName}</td>
            <td>{item.countryName}</td>
            <td>
              <span>
                {item.mobileMoney} {item.cashPickup} {item.bankDeposit}
              </span>
            </td>
            <td>
              <div className="display-caption steps-inputs-wrapper action-column">
                <span className="steps-inputs-column3 btn-padding">
                  <button className="btn-action" onClick={(e) => editBeneficiary(item)}>
                    Edit
                    <i style={{ padding: "2px 0px" }} className="fa fa-edit" aria-hidden="true"></i>
                  </button>
                </span>
                <span className="steps-inputs-column2 btn-padding">
                  <button className="btn-action" onClick={(e) => openModal(item)}>
                    Delete
                    <i style={{ padding: "2px 0px" }} className="fa fa-remove" aria-hidden="true"></i>
                  </button>
                </span>
                <span className="steps-inputs-column1 btn-padding">
                  <button className="btn-action" onClick={(e) => sendMoney(item)}>
                    Send Money
                    <i style={{ padding: "2px 0px" }} className="fa fa-send" aria-hidden="true"></i>
                  </button>
                </span>
              </div>
            </td>
          </tr>
        );
      })
    );

  return (
    <>
      {modal ? (
        <CustomModal
          show={modal}
          close={closeModal}
          delete={() => deleteBeneficiary({ setIsLoading, beneficiaryId, setModal, setBeneficiaryDeleted })}
        />
      ) : null}

      {isLoading ? (
        <div className="mainDiv">
          <div className="loading"></div>
        </div>
      ) : null}
      <section>
        <div className="transactions-wrapper">
          <form className="steps-inputs-wrapper">
            <div className="process-form align-items-center mob-column display-flex">
              <div className="transhistory-half-input">
                <Select
                  name="filterOption"
                  value={filterOption ? filterOption : ""}
                  onChange={(val) => setFilterOption(val)}
                  options={FILTER_OPTIONS}
                  getOptionLabel={(option) => option.filterOption}
                  getOptionValue={(option) => option.option}
                  isClearable={true}
                  placeholder="Filter By..."
                  styles={defaultStyle}
                />
              </div>
              {filterOption?.option === 1 ? (
                <div className="transhistory-half-input">
                  <input
                    className={`form-control form-input `}
                    value={name}
                    type="search"
                    name="name"
                    onChange={({ target }) => setName(target.value)}
                    onFocus={() => setOnFocus(true)}
                    onBlur={() => setOnFocus(false)}
                    placeholder="Type Name"
                    onKeyDown={(event) => (event.key === "Enter" ? setOnFocus(false) : setOnFocus(true))}
                  />
                </div>
              ) : (
                <div className="transhistory-half-input">
                  <Select
                    name="country"
                    value={country}
                    onChange={(val) => setCountry(val)}
                    options={selectedCountry}
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.id}
                    isClearable={true}
                    placeholder="Select to filter"
                    styles={defaultStyle}
                  />
                </div>
              )}
              <div className="steps-inputs-column1">
                <Link to="/add-beneficiary">Add New Beneficiary</Link>
              </div>
            </div>
          </form>

          <div className="transaction-table">
            <table>
              <thead>
                <tr>
                  <th width="20%">Name</th>
                  <th width="20%">Nick Name</th>
                  <th width="20%">Country</th>
                  <th width="20%">Channel</th>
                  <th width="40%">Action</th>
                </tr>
              </thead>
              <tbody>{tableBody}</tbody>

              <tfoot className="footer-no-border">
                <tr>
                  <td colSpan="4">
                    <div className="pagination-app">
                      <PaginationComponent
                        totalPages={totalPages}
                        currentPage={currentPage}
                        handlePageChange={handlePageChange}
                        handleNextClick={handleNextClick}
                        handlePrevClick={handlePrevClick}
                      />
                    </div>
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </section>
    </>
  );
}
