import { toast } from "react-toastify";
import { APIKEY } from "../../constants/Configuration";
import { removeSessionData } from "../../utils/session";
import { version } from "../../utils/version";

const axios = require("axios");

axios.defaults.headers.common = { Accept: "application/json" };
axios.defaults.headers.common = { ContentType: "application/json" };

axios.defaults.withCredentials = true;

axios.interceptors.request.use(function (config) {
  config["Access-Control-Allow-Credentials "] = true;
  // if (config.url.includes("/api/KYC")) {
    if (config.url.includes("/api/v1/kyc")) {
    config.headers.Authorization = APIKEY;
  } else {
    config.headers.Authorization = `Bearer ${localStorage.getItem("token")}`;
  }
  return config;
});

axios.interceptors.response.use(
  (res) => {
    if (res.headers?.app_version && res.headers?.app_version !== version) {
      window.location.reload(true); // a prompt can be used instead
    }
    return Promise.resolve(res);
  },
  (err) => {
    if (err?.response?.status === 401) {
      toast.dismiss();
      toast.error("Unauthorized! Session expired or requires login");
      removeSessionData("userName");
      removeSessionData("token");
      localStorage.clear();
      window.location = "/login?unauthorized";
      return;
    }
    return Promise.reject(err);
  }
);

export default axios;
