import { useEffect, useState } from "react";
import axios from "../api/axios/axios";

export default function useFetchBridgeBanks(countryID, paymentService) {
  const [bridgeBank, setBridgeBank] = useState([]);

  useEffect(() => {
    if (countryID && paymentService) {
      axios
        .get(`/api/Utility/GetBridgeBanks/${countryID}/${paymentService}`)

        .then((res) => {
          setBridgeBank(res.data);
        })
        .catch((err) => {
          setBridgeBank([]);
        });
    }
  }, [countryID, paymentService]);

  return { bridgeBank, setBridgeBank };
}
