import { useCallback, useEffect, useState } from "react";

import axios from "../api/axios/axios";

const KYC_STATUS = {
  PENDING: "Pending",
  REJECTED: "Rejected",
  PENDING_ADDITIONAL_DOCUMENTS: "Pending Additional Documents",
  NOT_DONE: "NotDone",
  EXPIRED: "Expired",
  FAILED: "Failed",
  CONFIRMED: "Confirmed",
};

export function useFetchAllUserDetails() {
  //KYC STATES
  const [kycCheck, setKycCheck] = useState(false);
  const [kycMessage, setKycMessage] = useState("Your KYC is not complete. Please complete your KYC to proceed.");
  const [kycPath, setKycPath] = useState("/kyc");
  const [cta, setCta] = useState("Start KYC");
  const [kycStatus, setKycStatus] = useState();
  const [kycDetails, setKycDetails] = useState(null);
  const [kycLoader, setKycLoader] = useState(false);

  const fetchUserDetails = useCallback(async () => {
    setKycLoader(true);
    try {
      const userDetails = await axios.get(`/api/Utility/GetKycDetails`);

      // KYC STATUS ====================================================================================================================
      if (userDetails.data) {
        setKycLoader(false);

        setKycDetails({ ...userDetails.data, userID: atob(userDetails?.data?.userID) });
        let kyc = userDetails.data?.kycConfirmation;
        const { PENDING, REJECTED, PENDING_ADDITIONAL_DOCUMENTS, NOT_DONE, EXPIRED, FAILED, CONFIRMED } = KYC_STATUS;

        if (PENDING === kyc || REJECTED === kyc || FAILED === kyc) {
          let status =
            kyc === PENDING
              ? "Your KYC is under review"
              : kyc === REJECTED
              ? "Your KYC was rejected"
              : "Your KYC failed";
          setKycMessage(
            `${status}. For further clarification please contact our  customer service at:
              WhatsApp +254770999865, +254 709 656 000 Or
              Email customerservice@upesi.co.ke`
          );

          setCta("OK");
          setKycPath("/dashboard");
          setKycStatus("Pending");
          setKycCheck(true);
        } else if (PENDING_ADDITIONAL_DOCUMENTS === kyc) {
          setKycMessage("Please upload Additional documents.");
          setKycStatus("Pending Additional Documents");
          setCta("Proceed");
          setKycPath("/uploadAddDoc");
          setKycCheck(true);
        } else if (NOT_DONE === kyc || EXPIRED === kyc) {
          setKycMessage("Please complete your KYC to proceed.");
          setKycStatus(kyc === EXPIRED ? "Expired" : "Not Done");
          setCta("Start KYC");
          setKycPath("/kyc");
          // navigate
          setKycCheck(true);
        } else if (CONFIRMED === kyc) {
          setKycStatus("Completed");
        }
      } else {
        setKycLoader(false);
        return "Pending";
      }
    } catch (error) {
      setKycLoader(false);
    }
  }, []);

  useEffect(() => {
    fetchUserDetails();
  }, [fetchUserDetails]);

  return { kycStatus, cta, kycPath, kycMessage, kycCheck, kycDetails, kycLoader, setKycLoader };
}
