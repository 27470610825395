/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import Facebook from "../../../../assets/images/icons/FB-01.png";
import Instagram from "../../../../assets/images/icons/Insta-01.png";
import Linkedin from "../../../../assets/images/icons/link-01.png";
import Twitter from "../../../../assets/images/icons/twitter-01.png";
import Logo from "../../../../assets/images/upesi-white-logo.png";
import { useCountry } from "../../../../context/route-context";
import { version } from "../../../../utils/version";
import styles from "./Footer.module.css";

const Footer = () => {
  const ugandaFooter = {
    email: "info.uganda@upesimts.com",
    phoneNumber: "+256 789 936 197",
    address: "Plot 13, Shop 106 Mukwano Courts,",
    street: "Buganda Road",
  };

  const kenyaFooter = {
    email: "info@upesi.co.ke",
    phoneNumber: "0709 656 000",
    address: "Morning Side Office Park, 3rd Floor,",
    street: "Ngong Road",
  };

  const [footer, setfooter] = useState(kenyaFooter);

  const { country } = useCountry();

  useEffect(() => {
    if (country === "ug") {
      setfooter(ugandaFooter);
    } else {
      setfooter(kenyaFooter);
    }
  }, [country]);

  return (
    <Row className={styles.footer}>
      <Col md="4" className={styles.footer__section}>
        <div className={styles.white_logo_wrapper}>
          <img className="w-100" src={Logo} alt="" />
        </div>
        <ul className="">
          <li>
            <Link className={styles.footer_link} to="/about-upesi">
              About
            </Link>
          </li>

          <li>
            <Link className={styles.footer_link} to="/contact">
              Work with us
            </Link>
          </li>
        </ul>
      </Col>
      <Col md="4">
        <h1>Get in Touch</h1>
        <ul>
          <li>
            <i className="fa fa-envelope" aria-hidden="true"></i>{" "}
            <span>{footer.email}</span>
          </li>
          <li>
            <i className="fa fa-phone" aria-hidden="true"></i>{" "}
            <span>{footer.phoneNumber}</span>
          </li>
          <li>
            <i className="fa fa-map-marker" aria-hidden="true"></i>
            <span>
              {footer.address}
              <br />
              {footer.street}
            </span>
          </li>
        </ul>
      </Col>
      <Col
        md="4"
        className="d-flex flex-direction-column justify-content-between"
      >
        <h1>Legal</h1>
        <ul>
          <li>
            <Link className={styles.footer_link} to="/terms">
              Terms and Conditions
            </Link>
          </li>
          <li>
            <Link className={styles.footer_link} to="/policy">
              Privacy Policy
            </Link>
          </li>
        </ul>
        <ul className="d-flex w-50 mt-4 pt-4">
          <li className="px-1">
            <a
              href="https://www.facebook.com/UpesiMoneyTransfer"
              target="blank"
            >
              <img className="w-75" src={Facebook} alt="Facebook icon" />
            </a>
          </li>
          <li className="px-1">
            <a href="https://twitter.com/upesimoney" target="blank">
              <img className="w-75" src={Twitter} alt="Twitter icon" />
            </a>
          </li>
          <li className="px-1">
            <a
              href="https://www.instagram.com/upesimoneytransfer/"
              target="blank"
            >
              <img className="w-75" src={Instagram} alt="Instagram icon" />
            </a>
          </li>
          <li className="px-1">
            <a
              href="https://ke.linkedin.com/company/upesi-money-transfer-ltd"
              target="blank"
            >
              <img className="w-75" src={Linkedin} alt="Linkedin icon" />
            </a>
          </li>
        </ul>
      </Col>
      <div className="d-flex justify-content-center py-2">
        <p className={styles.version}>version : {version}</p>
      </div>
    </Row>
  );
};

export default Footer;
