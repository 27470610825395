import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import { forwardRef } from "react";
import Security from "../../../../assets/images/icons/cyber-security-01.png";
import Affordable from "../../../../assets/images/icons/affordable-01.png";
import Fast from "../../../../assets/images/icons/FAST-01.png";
import styles from "./WhyUs.module.css";
import { useLocation } from "react-router";
import { useState } from "react";
import { useEffect } from "react";

const WhyUsUg = forwardRef((props, ref) => {
  const [licenseInfo, setLincenseInfo] = useState("");
  const cardItems = [
    { icon: Security, alt: "Cyber Security Icon", title: "We Are Safe", variant: "white" },
    { icon: Fast, alt: "Clock Icon", title: "We Are Fast", variant: "white" },
    { icon: Affordable, alt: "Money Icon", title: "We are Affordable", variant: "white" },
  ];

  const { pathname } = useLocation();

  useEffect(() => {
    if (pathname.includes("ug")) {
      setLincenseInfo(" by Bank of Uganda");
    } else {
      setLincenseInfo("in Kenya by the Central Bank of Kenya (CBK)");
    }
  }, [pathname]);

  return (
    <div className={`${styles.why_us} pt-5 px-5`} ref={ref}>
      <h1 className={styles.why_us__title}>Why Us</h1>
      <h3 className={styles.why_us__text}>
        Upesi is a homegrown African solution that enables efficient, reliable, and affordable global money transfer.
        The Company is currently licensed {licenseInfo}.
      </h3>
      <Row className={styles.cards_row} md={3} sm={2} xs={1}>
        {cardItems.map((item, idx) => {
          return (
            <Col key={idx}>
              <Card
                bg={item.variant}
                key={item.title}
                text={item.variant.toLowerCase() === "white" ? "dark" : "white"}
                className={`${styles.why_us__card} m-4 ${
                  item.variant === "navy-blue" ? `${styles.why_us__bg__navy_blue}` : `${styles.why_us__bg__white}`
                }`}
              >
                <Card.Body className={styles.why_us__card_body}>
                  <Card.Title className={styles.why_us__card_title}>{item.title} </Card.Title>
                  <Card.Text className={styles.card_icon_wrapper}>
                    <img src={item.icon} alt={item.alt} />
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          );
        })}
      </Row>
    </div>
  );
});

export default WhyUsUg;
