import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import useWindowDimensions from "../../../../hooks/useWindowDimensions";
import Countries from "../../Components/Countries/Countries";
import { UGFlag } from "../../Components/Header/Header";
import styles from "../../Components/Header/Header.module.css";
import Partners from "../../Components/Partners/Partners";
import SendMoney from "../../Components/SendMoney/SendMoney";
import UGHeaderCover from "../../Components/UGHeaderCover/UGHeaderCover";
import UGSignUpCta from "../../Components/UgandaCTA/UgandaCTA";
import FloatingWindow from '../../Components/AdvertPopup/advert_popup.jsx'
import WhyUsUg from "../../Components/WhyUs/whyUsUg";
import "./Uganda.css";

const Uganda = () => {
  const { width } = useWindowDimensions();
  const [value, setValue] = useState(
    "https://play.google.com/store/search?q=upesi+money+transfer&c=apps"
  );
const [showPopup, setShowPopup] = useState(true)
const closePopup = () => setShowPopup(false)

  localStorage.setItem(
    "country",
    JSON.stringify({
      flag: UGFlag,
      name: "UG",
      headerBgColor: styles.transparent,
    })
  );

  const location = window.location;

  useEffect(() => {
    const userAgent = navigator.userAgent || navigator.vendor;

    if (/android/i.test(userAgent)) {
      setValue(
        "https://play.google.com/store/search?q=upesi+money+transfer&c=apps"
      );
    } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      setValue("https://apps.apple.com/ke/app/upesi/id6448977046");
    }
  }, []);

  const ugPage = () => (
    <Container
      className={`container justify-content-center ${
        width > 820 ? "w-75" : ""
      }`}
    >
      <UGHeaderCover />
      {
  showPopup && <FloatingWindow onClose={closePopup}></FloatingWindow>
}

      <WhyUsUg />
      <UGSignUpCta />
      <SendMoney />
      <Countries />
      <Partners />
    </Container>
  );

  return (
    <div
      className="container-fluid p-0"
      style={{ display: "flex", flexDirection: "column" }}
    >
      {location.search.includes("download")
        ? (window.location.href = value)
        : ugPage()}
    </div>
  );
};

export default Uganda;
