import { useEffect, useState } from "react";
import axios from "../api/axios/axios";

const initialState = [];
export default function useFetchBeneficiaries() {
  const [initData, setInitData] = useState(initialState);
  const [beneficiaries, setBeneficiaries] = useState(initialState);
  const [message, setMessage] = useState("No beneficiaries added yet");
  const [isLoading, setIsLoading] = useState(true);
  const [beneficiaryDeleted, setBeneficiaryDeleted] = useState(false);

  useEffect(() => {
    (async function () {
      setMessage("Loading...");
      try {
        const res = await axios.get(`/api/Beneficiary/GetBeneficiarieswithChannels`);
        setInitData(res.data);
        setBeneficiaries(res.data);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        setBeneficiaries([]);
      }
    })();
  }, [beneficiaryDeleted]);

  return { isLoading, message, initData, beneficiaries, setBeneficiaries, setIsLoading, setBeneficiaryDeleted };
}
