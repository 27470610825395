import React from "react";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

// TODO: make one file and reuse

export function SampleNextArrow(props) {
  const { className, onClick } = props;
  return (
    <div
      className={className + " landing-slick-next"}
      style={{
        padding: "0px",
        background: "grey",
        borderRadius: "50%",
        right: "25px",
        zIndex: "3",
      }}
      onClick={onClick}
    />
  );
}

export function SamplePrevArrow(props) {
  const { className, onClick } = props;
  return (
    <div
      className={className + " landing-slick-next"}
      style={{
        padding: "0px",
        background: "grey",
        borderRadius: "50%",
        left: "25px",
        zIndex: "3",
      }}
      onClick={onClick}
    />
  );
}
