import React from "react";
import paymentIcon from "../assets/images/icons/add-payment-icon.png";
import { useLocation } from "react-router-dom";

const TransactionDetails = () => {
  const location = useLocation();
  const transactionDetails = location.state;

  return (
    <>
      <div className="send-total-wrapper mob-padding-remove">
        <div className="send-fees">
          <div className="payment-details-add">
            <img src={paymentIcon} alt="payment icon" />
            <h2>Transaction Details</h2>
          </div>
          <ol>
            <li>
              <h3>Txn ref no.</h3>
              <p>{transactionDetails.transaction_ref}</p>
            </li>
            <li>
              <h3>Receiver country</h3>
              <p>{transactionDetails.receiver_country_code}</p>
            </li>
            <li>
              <h3>Receiver name</h3>
              <p>{transactionDetails.receiver_full_name}</p>
            </li>
            <li>
              <h3>Sending amount</h3>
              <p>{transactionDetails.send_amount}</p>
            </li>
            <li>
              <h3>Exchange rate</h3>
              <p>{transactionDetails.exchange_rate}</p>
            </li>
            <li>
              <h3>Receiving Amount</h3>
              <p>{transactionDetails.receiver_amount}</p>
            </li>

            <li>
              <h3>Status</h3>
              <p>{transactionDetails.transactionstatus}</p>
            </li>
            <li>
              <h3>Booking Date</h3>
              <p>{transactionDetails.transaction_date}</p>
            </li>
          </ol>
        </div>
      </div>
    </>
  );
};

export default TransactionDetails;
