import { useEffect, useState } from "react";
import axios from "../api/axios/axios";

export default function useFetchAllBeneficiaryDetailsById(beneficiaryId) {
  //TODO: this implementation to be removed in future- when multiple accounts are created
  const [beneficiaryServiceType, setBeneficiaryServiceType] = useState([]);

  useEffect(() => {
    if (beneficiaryId) {
      (async function () {
        try {
          const response = await axios.get(`/api/Beneficiary/GetAllBeneficiaryDetailsById/${beneficiaryId}`);
          const { result } = response.data;

          let benDeliveryOption =
            result?.beneficiaryBankDetails.length > 0
              ? { id: 1, name: "Bank Deposit" }
              : result?.beneficiaryLocationDetails.length > 0
              ? { id: 2, name: "Cash Pickup" }
              : result?.beneficiaryMobileWalletDetails.length > 0
              ? { id: 3, name: " Mobile Money" }
              : {};
          setBeneficiaryServiceType(benDeliveryOption);
        } catch (err) {}
      })();
    }
  }, [beneficiaryId]);

  return { beneficiaryServiceType };
}

//TODO: to be removed - get beneficiary service type from beneficiary obejct
export const getBeneficiaryServiceType = async (obj, navigate, action) => {
  // TODO: just get id on obj value, the rest of the data from the response
  try {
    const response = await axios.get(`/api/Beneficiary/GetAllBeneficiaryDetailsById/${obj.beneficiaryID}`);

    const {
      result: {
        beneficiaryDetails: {
          beneficiaryBankDetails,
          beneficiaryLocationDetails,
          beneficiaryMobileWalletDetails,
          relationship,
          relationshipID,
          callingCode,
          countryID,
        },
      },
    } = response.data;

    let benDeliveryOption =
      beneficiaryBankDetails?.length > 0
        ? {
            serviceId: 1,
            serviceName: "Bank Deposit",
            serviceTypeDetails: beneficiaryBankDetails[0],
          }
        : beneficiaryLocationDetails?.length > 0
        ? {
            serviceId: 2,
            serviceName: "Cash Pickup",
            serviceTypeDetails: beneficiaryLocationDetails[0],
          }
        : beneficiaryMobileWalletDetails?.length > 0
        ? {
            serviceId: 3,
            serviceName: " Mobile Money",
            serviceTypeDetails: beneficiaryMobileWalletDetails[0],
          }
        : { serviceId: 0, serviceName: "", serviceTypeDetails: {} };

    // eslint-disable-next-line no-unused-expressions
    action === "EDIT_BENEFICIARY"
      ? navigate("/edit-beneficiary", {
          state: {
            ...obj,
            id: obj?.beneficiaryID,
            countryID,
            action: "EDIT_BENEFICIARY",
            relationShip: relationship,
            relationshipID,
            callingCode,
            ...response.data?.result?.beneficiaryDetails,
            ...benDeliveryOption,
          },
        })
      : navigate("/money-transfer", {
          state: { ...obj, id: obj?.beneficiaryID, countryID, id: obj?.beneficiaryID, countryID, ...benDeliveryOption },
        });
  } catch (err) {}
};
